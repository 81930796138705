import type { BadgeProps, BadgeVariant } from '@meterup/atto';
import { Badge } from '@meterup/atto';
import { api } from '@meterup/proto';
import { capitalize } from 'lodash-es';
import React from 'react';
import { match } from 'ts-pattern';

import { isDefined } from '../helpers/isDefined';
import { formatLifecycleStatus } from '../helpers/lifecycleStatus';
import { formatISPProduct } from '../topics/isp_product';
import { formatISPStatus } from '../topics/isp_status';

// Value obtained via careful measurement of Slack message from Joe. Signal
// strength greater than this value is considered "good", otherwise "bad".
const SIGNAL_STRENGTH_QUALITY_THRESHOLD_DBM = -74;

type CurriedBadgeProps = Partial<BadgeProps>;

export type EnabledDisabledStatus = 'enabled' | 'disabled';
export type EnabledDisabledUnknownStatus = EnabledDisabledStatus | 'unknown';
export type ConnectionStatus = 'online' | 'offline' | 'wired';
export type OnlineOfflineStatus = 'online' | 'offline';
export type DeviceStatus = OnlineOfflineStatus | 'draft';
export type RadioStatus = OnlineOfflineStatus | EnabledDisabledStatus;

export function PositiveBadge({
  ends = 'pill',
  size = 'small',
  variant = 'positive',
  children,
  ...props
}: CurriedBadgeProps) {
  return (
    <Badge {...props} ends={ends} size={size} variant={variant}>
      {children}
    </Badge>
  );
}

export function NegativeBadge({
  ends = 'pill',
  size = 'small',
  variant = 'negative',
  children,
  ...props
}: CurriedBadgeProps) {
  return (
    <Badge {...props} ends={ends} size={size} variant={variant}>
      {children}
    </Badge>
  );
}

export function NeutralBadge({
  ends = 'pill',
  size = 'small',
  variant = 'neutral',
  children,
  ...props
}: CurriedBadgeProps) {
  return (
    <Badge {...props} ends={ends} size={size} variant={variant}>
      {children}
    </Badge>
  );
}

export function AlternativeBadge({
  ends = 'pill',
  size = 'small',
  variant = 'alternative',
  children,
  ...props
}: CurriedBadgeProps) {
  return (
    <Badge {...props} ends={ends} size={size} variant={variant}>
      {children}
    </Badge>
  );
}

export function OnlineBadge({ children = 'Online', ...props }: CurriedBadgeProps) {
  return <PositiveBadge {...props}>{children}</PositiveBadge>;
}

export function OfflineBadge({ children = 'Offline', ...props }: CurriedBadgeProps) {
  return <NegativeBadge {...props}>{children}</NegativeBadge>;
}

export function DraftBadge({ children = 'Draft', ...props }: CurriedBadgeProps) {
  return <AlternativeBadge {...props}>{children}</AlternativeBadge>;
}

export function UnknownBadge({ children = 'Unknown', ...props }: CurriedBadgeProps) {
  return <NeutralBadge {...props}>{children}</NeutralBadge>;
}

export function PendingBadge({ children = 'Pending', ...props }: CurriedBadgeProps) {
  return <NeutralBadge {...props}>{children}</NeutralBadge>;
}

export function WiredBadge({ children = 'Wired', ...props }: CurriedBadgeProps) {
  return <NeutralBadge {...props}>{children}</NeutralBadge>;
}

export function WirelessBadge({ children = 'Wireless', ...props }: CurriedBadgeProps) {
  return <NeutralBadge {...props}>{children}</NeutralBadge>;
}

export function DisabledBadge({ children = 'Disabled', ...props }: CurriedBadgeProps) {
  return <NeutralBadge {...props}>{children}</NeutralBadge>;
}

export function EnabledBadge({ children = 'Enabled', ...props }: CurriedBadgeProps) {
  return <PositiveBadge {...props}>{children}</PositiveBadge>;
}

export function ProvisionedBadge({ children = 'Provisioned', ...props }: CurriedBadgeProps) {
  return <AlternativeBadge {...props}>{children}</AlternativeBadge>;
}

export function InstalledPrimaryBadge({ children = 'Primary', ...props }: CurriedBadgeProps) {
  return <PositiveBadge {...props}>{children}</PositiveBadge>;
}

export function InstalledBackupBadge({ children = 'Backup', ...props }: CurriedBadgeProps) {
  return <AlternativeBadge {...props}>{children}</AlternativeBadge>;
}

export const OnlineOfflineStatusBadge = ({
  value,
  ...props
}: { value: 'online' | 'offline' | string } & CurriedBadgeProps) =>
  match(value)
    .with('online', () => <OnlineBadge {...props} />)
    .with('offline', () => <OfflineBadge {...props} />)
    .otherwise(() => <UnknownBadge {...props} />);

export const LifecycleStatusBadge = ({
  value,
  ...props
}: { value: api.LifecycleStatus } & CurriedBadgeProps) =>
  match(value)
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_PROVISIONED, () => <ProvisionedBadge {...props} />)
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_PRIMARY, () => (
      <InstalledPrimaryBadge {...props} />
    ))
    .with(api.LifecycleStatus.LIFECYCLE_STATUS_INSTALLED_BACKUP, () => (
      <InstalledBackupBadge {...props} />
    ))
    .otherwise(() => <NeutralBadge {...props}>{formatLifecycleStatus(value)}</NeutralBadge>);

export const OnlineOfflineDraftBadge = ({
  value,
  ...props
}: {
  value: 'online' | 'offline' | 'draft' | string;
} & CurriedBadgeProps) =>
  match(value)
    .with('online', () => <OnlineBadge {...props} />)
    .with('offline', () => <OfflineBadge {...props} />)
    .with('draft', () => <DraftBadge {...props} />)
    .otherwise((unknownStatus) => <UnknownBadge {...props}>{unknownStatus}</UnknownBadge>);

export const WiredWirelessBadge = ({
  value,
  ...props
}: {
  value: 'wired' | 'wireless' | string;
} & CurriedBadgeProps) =>
  match(value)
    .with('wired', () => <WiredBadge {...props} />)
    .with('wireless', () => <WirelessBadge {...props} />)
    .otherwise((unknownStatus) => <UnknownBadge {...props}>{unknownStatus}</UnknownBadge>);

export const SignalStrengthBadge = ({
  value,
  ...props
}: {
  value: number | null | undefined;
} & CurriedBadgeProps) =>
  match(value)
    .when(
      (v) => isDefined(v) && v >= SIGNAL_STRENGTH_QUALITY_THRESHOLD_DBM,
      () => <PositiveBadge {...props}>{value} dBm</PositiveBadge>,
    )
    .when(
      (v) => isDefined(v) && v < SIGNAL_STRENGTH_QUALITY_THRESHOLD_DBM,
      () => <NegativeBadge {...props}>{value} dBm</NegativeBadge>,
    )
    .otherwise(() => null);

export const UserRoleBadge = ({
  value,
  ends = 'card',
  ...props
}: { value: api.CompanyMembershipRole } & CurriedBadgeProps) =>
  match(value)
    .with(api.CompanyMembershipRole.admin, () => (
      <NegativeBadge ends={ends} {...props}>
        Admin
      </NegativeBadge>
    ))
    .with(api.CompanyMembershipRole.member, () => (
      <NeutralBadge ends={ends} {...props}>
        Member
      </NeutralBadge>
    ))
    .with(api.CompanyMembershipRole.guest, () => (
      <NeutralBadge ends={ends} {...props}>
        Guest
      </NeutralBadge>
    ))
    .with(api.CompanyMembershipRole.unknown, () => (
      <NeutralBadge ends={ends} {...props}>
        Unknown
      </NeutralBadge>
    ))
    .exhaustive();

export function ServicePlanProductBadge({ value }: { value: api.ISPProduct }) {
  return <Badge size="small">{formatISPProduct(value)}</Badge>;
}

export function ServicePlanStatusBadge({ value }: { value: api.ISPStatus }) {
  return <Badge size="small">{formatISPStatus(value)}</Badge>;
}

export function NetworkInterfaceBadge({ value }: { value?: string }) {
  return <Badge size="small">{value ?? 'None'}</Badge>;
}

export function ValueOrUnknownBadge({ value }: { value?: string }) {
  return <Badge size="small">{value || 'Unknown'}</Badge>;
}

export function CountBadge({
  value,
  arrangement = 'leading-icon',
  icon,
  ...props
}: {
  value?: number;
} & CurriedBadgeProps) {
  return value === 0 ? (
    <NeutralBadge {...props} arrangement={arrangement}>
      {value ?? 0}
    </NeutralBadge>
  ) : (
    <AlternativeBadge {...props} icon={icon} arrangement={arrangement}>
      {value ?? 'None'}
    </AlternativeBadge>
  );
}

export function OnlineOfflineClientCountBadge({
  value,
  arrangement = 'leading-icon',
  icon = 'client',
  ...props
}: { value?: number } & CurriedBadgeProps) {
  return value === 0 ? (
    <NeutralBadge {...props} arrangement={arrangement}>
      {value ?? 0}
    </NeutralBadge>
  ) : (
    <PositiveBadge {...props} arrangement={arrangement} icon={icon}>
      {value ?? 'None'}
    </PositiveBadge>
  );
}

export type EnabledStatus = boolean | EnabledDisabledUnknownStatus;

export const EnabledStatusBadge = ({ status }: { status?: EnabledStatus }) =>
  match(status)
    .with(true, 'enabled', () => <EnabledBadge />)
    .with(false, 'disabled', () => <DisabledBadge />)
    .with('unknown', undefined, () => <UnknownBadge />)
    .exhaustive();

export const ConnectionStatusBadge = ({ status }: { status: ConnectionStatus }) =>
  match(status)
    .with('online', () => <OnlineBadge />)
    .with('offline', () => <OfflineBadge />)
    .with('wired', () => <WiredBadge />)
    .exhaustive();

export const ISPStatusBadge = ({ status }: { status: api.ISPStatus }) =>
  match(status)
    .with(api.ISPStatus.IS_PRIMARY, () => (
      <Badge ends="card" size="small" variant="brand">
        Primary
      </Badge>
    ))
    .with(api.ISPStatus.IS_BACKUP, () => (
      <Badge ends="card" size="small" variant="neutral">
        Backup
      </Badge>
    ))
    .otherwise(() => <UnknownBadge />);

export const onlineOfflineBadgeVariant = (status: string): BadgeVariant =>
  match(status)
    .with('online', () => 'positive' as const)
    .with('offline', () => 'negative' as const)
    .otherwise(() => 'neutral' as const);

export const DeviceStatusBadge = ({ status }: { status: string }) =>
  match(status)
    .with('online', () => <OnlineBadge size="large" />)
    .with('offline', () => <OfflineBadge size="large" />)
    .with('draft', () => (
      <Badge size="large" variant="neutral" ends="pill">
        Draft
      </Badge>
    ))
    .otherwise(() => <UnknownBadge />);

export const DrawerHeaderStatus = ({ status }: { status: string }) =>
  match(status)
    .with('online', () => <OnlineBadge />)
    .with('offline', () => <OfflineBadge />)
    .with('draft', () => (
      <Badge size="small" variant="neutral" ends="pill">
        Draft
      </Badge>
    ))
    .otherwise(() => <UnknownBadge />);

export function RotationStrategyBadge({ value }: { value: string }) {
  return (
    <Badge
      variant="neutral"
      ends="pill"
      size="small"
      icon="arrows-rotate"
      arrangement="leading-icon"
    >
      {capitalize(value.toLocaleLowerCase())}
    </Badge>
  );
}

export const OnlineOfflineWifiIconBadge = ({ status }: { status: string }) =>
  match(status)
    .with('online', () => (
      <Badge icon="wifi" arrangement="hidden-label" ends="pill" size="small" variant="positive">
        Online
      </Badge>
    ))
    .otherwise(() => (
      <Badge icon="wifi" arrangement="hidden-label" ends="pill" size="small" variant="neutral">
        Offline
      </Badge>
    ));

export const RadioStatusBadge = ({ status }: { status?: RadioStatus }) =>
  match(status)
    .with('online', 'enabled', () => (
      <Badge
        arrangement="leading-icon"
        size="small"
        variant="positive"
        ends="pill"
        icon="checkmark-circle"
      >
        Enabled
      </Badge>
    ))
    .with('offline', 'disabled', () => (
      <Badge
        arrangement="leading-icon"
        size="small"
        variant="neutral"
        ends="pill"
        icon="cross-circle"
      >
        Disabled
      </Badge>
    ))
    .with(undefined, () => (
      <Badge size="small" variant="neutral" ends="pill">
        Unknown
      </Badge>
    ))
    .exhaustive();

export const ContentFilterActionBadge = ({
  value,
  ...props
}: {
  value: api.ContentFilterAction;
} & CurriedBadgeProps) =>
  match(value)
    .with(api.ContentFilterAction.CONTENTFILTER_ACTION_BLOCK, () => (
      <NeutralBadge {...props}>Block</NeutralBadge>
    ))
    .with(api.ContentFilterAction.CONTENTFILTER_ACTION_ALLOW, () => (
      <PositiveBadge {...props}>Allow</PositiveBadge>
    ))
    .with(api.ContentFilterAction.CONTENTFILTER_ACTION_UNKNOWN, () => (
      <NeutralBadge {...props}>Unknown</NeutralBadge>
    ))
    .exhaustive();

export function TaggedOrUntaggedBadge({
  isUntagged,
  children = isUntagged ? 'Unagged' : 'Tagged',
  ...props
}: { isUntagged: boolean } & CurriedBadgeProps) {
  return isUntagged ? (
    <AlternativeBadge ends="card" {...props}>
      {children}
    </AlternativeBadge>
  ) : (
    <NeutralBadge ends="card" {...props}>
      {children}
    </NeutralBadge>
  );
}

export function TCPOrUDPBadge({ value, ...props }: { value: 'tcp' | 'udp' } & CurriedBadgeProps) {
  return value === 'tcp' ? (
    <NeutralBadge ends="card" {...props}>
      TCP
    </NeutralBadge>
  ) : (
    <NeutralBadge ends="card" {...props}>
      UDP
    </NeutralBadge>
  );
}
