import type { SizerProp } from '@meterup/atto';
import type {
  Cell,
  Column,
  ColumnDef,
  FilterFnOption,
  OnChangeFn,
  Row,
  RowData,
  RowSelectionState,
  SortingState,
  Table as ReactTable,
} from '@tanstack/react-table';
import type { Dispatch, ForwardedRef, SetStateAction } from 'react';
import type { To } from 'react-router-dom';
import {
  sizer,
  Table,
  TableBody,
  TableCell,
  TableCellBuffer,
  TableCellState,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  Tooltip,
} from '@meterup/atto';
import { rankings, rankItem } from '@tanstack/match-sorter-utils';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import React, {
  memo,
  useCallback,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Link } from 'react-router-dom';

import { isDefined } from '../helpers/isDefined';

export type { ColumnDef, Row, SortingState };
export { createColumnHelper, rankings, rankItem };

declare module '@tanstack/table-core' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/no-shadow
  interface ColumnMeta<TData extends RowData, TValue> {
    isLeading?: boolean;
    alignment?: 'start' | 'center' | 'end';
    condense?: boolean;
    hideSortIcon?: boolean;
    minBreakpoint?: 'sm' | 'md' | 'lg' | 'xl';
    tooltip?: { contents: React.ReactNode };
    stuck?: 'top' | 'right' | 'bottom' | 'left';
    internal?: boolean;
    width?: SizerProp;
    maxWidth?: SizerProp;
    minWidth?: SizerProp;
  }
}

// Based on https://tanstack.com/table/v8/docs/api/features/filters#filter-meta
function defaultGlobalFilterFn<D extends any>(
  row: Row<D>,
  columnId: string,
  filterValue: string,
  addMeta: (meta: any) => void,
) {
  // make dots, dashes, colons, and underscores interchangeable
  const pattern = filterValue.replace(/[.\-_:]/g, '');

  const itemRank = rankItem(row.getValue(columnId), pattern);

  addMeta(itemRank);

  return itemRank.passed;
}

const getSizeProps = (_: ReactTable<any>, column: Column<any>) => {
  const meta = column.columnDef.meta ?? {};
  const { alignment = 'start', condense, hideSortIcon, width, maxWidth, minWidth } = meta;

  return {
    alignment,
    condense,
    hideSortIcon,
    style: {
      // eslint-disable-next-line no-nested-ternary
      width: maxWidth ? sizer(maxWidth) : width ? sizer(width) : undefined,
      // eslint-disable-next-line no-nested-ternary
      minWidth: minWidth ? sizer(minWidth) : width ? sizer(width) : undefined,
    },
  };
};

type GetLinkTo<D extends any> = (row: D) => To | null | undefined;
type IsRowDisabled<D> = (row: D) => boolean;
type IsRowSelected<D> = (row: D) => boolean;
type IsRowCollapsed<D> = (row: D) => boolean;
type OnRowDeselect<D> = (row: D) => void;
type OnRowClick<D> = (row: D) => void;

type SubAutoTableProps<D> = {
  data: D;
  isNested: boolean;
};

function AutoTableCellImpl<D extends any>({
  as,
  cell,
  table,
  isNavigable,
  isNested,
  to,
  isMultiSelected,
  ...remaining
}: {
  as?: React.ElementType;
  table: ReactTable<D>;
  cell: Cell<D, any>;
  isMultiSelected?: boolean;
  isNavigable?: boolean;
  isNested?: boolean;
  to?: To;
}) {
  const content = flexRender(cell.column.columnDef.cell, cell.getContext());
  const { hideSortIcon, ...sizeProps } = getSizeProps(table, cell.column);
  return (
    <TableCell
      key={cell.id}
      {...sizeProps}
      isLeading={cell.column.columnDef.meta?.isLeading}
      isNavigable={isNavigable}
      isNested={isNested}
      internal={cell.column.columnDef.meta?.internal}
      stuck={cell.column.columnDef.meta?.stuck}
      as={cell.column.columnDef.meta?.isLeading ? as : undefined}
      to={cell.column.columnDef.meta?.isLeading ? to : undefined}
      {...remaining}
    >
      {content}
    </TableCell>
  );
}

const AutoTableCell = memo(AutoTableCellImpl) as typeof AutoTableCellImpl;

interface AutoTableRowProps<D extends any> {
  table: ReactTable<D>;
  row: Row<D>;
  getLinkTo?: GetLinkTo<D>;
  isRowDisabled?: IsRowDisabled<D>;
  isRowSelected?: IsRowSelected<D>;
  isRowCollapsed?: IsRowCollapsed<Row<D>>;
  onToggleRowCollapse: (row: Row<D>) => void;
  onRowClick?: OnRowClick<D>;
  isMultiSelected?: boolean;
  isNested?: boolean;
  renderSubTable?: (props: SubAutoTableProps<D>) => JSX.Element;
}

function AutoTableRowImpl<D extends any>(
  {
    table,
    row,
    getLinkTo,
    isRowDisabled,
    isRowSelected,
    isRowCollapsed,
    onRowClick,
    onToggleRowCollapse,
    isMultiSelected,
    isNested,
    renderSubTable,
    ...restProps
  }: AutoTableRowProps<D>,
  ref?: ForwardedRef<HTMLTableRowElement>,
) {
  const to = getLinkTo?.(row.original);
  const isDisabled = isRowDisabled?.(row.original) ?? false;
  const isSelected = isRowSelected?.(row.original) ?? false;
  const localCollapsed = isRowCollapsed?.(row) ?? false;

  const handleClick = useCallback(() => {
    onRowClick?.(row.original);
  }, [onRowClick, row.original]);

  const subRowData = row.original;
  const subTable = renderSubTable ? renderSubTable({ data: subRowData, isNested: true }) : null;

  return (
    <>
      <TableRow
        ref={ref}
        key={row.id}
        isDisabled={isDisabled}
        isSelected={!!(isSelected || isMultiSelected)}
        isNested={isNested}
        onClick={handleClick}
      >
        <TableCellBuffer side="leading" />
        <TableCellState side="leading" />
        {renderSubTable && (
          <TableCell
            collapsable
            onClick={() => onToggleRowCollapse(row)}
            collapsed={localCollapsed}
          />
        )}
        {row.getVisibleCells().map((cell) => (
          <AutoTableCell
            as={isDefined(to) ? Link : undefined}
            to={isDefined(to) ? to : undefined}
            key={cell.id}
            table={table}
            cell={cell}
            isMultiSelected={isMultiSelected}
            isNested={isNested}
          />
        ))}
        <TableCellState side="trailing" />
        <TableCellBuffer side="trailing" />
      </TableRow>
      {renderSubTable && !localCollapsed && (
        <TableRow {...restProps}>
          <TableCell hasNested colSpan={1000}>
            {subTable}
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

export const AutoTableRow = React.forwardRef(AutoTableRowImpl) as <D extends any>(
  props: AutoTableRowProps<D> & { ref?: ForwardedRef<HTMLTableRowElement> },
) => ReturnType<typeof AutoTableRowImpl>;

interface AutoTableBodyProps<D extends any> {
  table: ReactTable<D>;
  rows: Row<D>[];
  multiSelectedRows?: RowSelectionState;
  actions?: React.ReactNode;
  getLinkTo?: GetLinkTo<D>;
  isRowDisabled?: IsRowDisabled<D>;
  isRowSelected?: IsRowSelected<D>;
  isRowCollapsed?: IsRowCollapsed<Row<D>>;
  onRowClick?: OnRowClick<D>;
  onToggleRowCollapse: (row: Row<D>) => void;
  isNested?: boolean;
  renderSubTable?: (props: SubAutoTableProps<D>) => JSX.Element;
}

type VirtualAutoTableBodyProps<D extends any> = {
  tableContainerRef: React.RefObject<HTMLDivElement>;
} & AutoTableBodyProps<D>;

function VirtualAutoTableBody<D extends any>({
  table,
  isRowDisabled,
  isRowSelected,
  isRowCollapsed,
  onToggleRowCollapse,
  isNested,
  getLinkTo,
  onRowClick,
  multiSelectedRows,
  renderSubTable,
  rows,
  actions,
  tableContainerRef,
}: VirtualAutoTableBodyProps<D>) {
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 36, // based on a precalculated size
    getScrollElement: () => {
      if (typeof tableContainerRef === 'object') {
        return tableContainerRef?.current ?? null;
      }
      return null;
    },
    overscan: 10,
    measureElement: (element) => element?.getBoundingClientRect().height ?? 36,
  });

  // This is a hack which solves the problem of not having the tableContainerRef.current defined.
  // This tends to happen on first and only render as a consequence of our Suspense setup
  // and will subsequently never be be defined unless there is a state change.
  // Results in an AutoTable with no rows when AutoTable is first rendered.
  // This should only be called exactly once.
  useLayoutEffect(() => {
    rowVirtualizer?.measure?.();
  }, [rowVirtualizer]);

  const virtualRows = rowVirtualizer.getVirtualItems();
  const paddingTop = virtualRows.length > 0 ? virtualRows[0].start : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? rowVirtualizer.getTotalSize() - virtualRows[virtualRows.length - 1].end
      : 0;

  return (
    <TableBody>
      {paddingTop > 0 && (
        <tr aria-hidden>
          <td style={{ height: `${paddingTop}px` }} />
        </tr>
      )}
      {virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];
        return (
          <AutoTableRow
            key={row.id}
            ref={(node) => rowVirtualizer.measureElement(node)}
            data-index={virtualRow.index}
            row={row}
            table={table}
            isRowDisabled={isRowDisabled}
            isRowSelected={isRowSelected}
            isRowCollapsed={isRowCollapsed}
            onToggleRowCollapse={onToggleRowCollapse}
            isNested={isNested}
            getLinkTo={getLinkTo}
            onRowClick={onRowClick}
            isMultiSelected={multiSelectedRows?.[row.id]}
            renderSubTable={renderSubTable}
          />
        );
      })}
      {actions && (
        <TableRow>
          <TableCellBuffer side="leading" />
          <TableCellState side="leading" />
          <TableCell colSpan={1000}>{actions}</TableCell>
          <TableCellState side="trailing" />
          <TableCellBuffer side="trailing" />
        </TableRow>
      )}
      {paddingBottom > 0 && (
        <tr aria-hidden>
          <td style={{ height: `${paddingBottom}px` }} />
        </tr>
      )}
    </TableBody>
  );
}

function AutoTableBody<D extends any>({
  table,
  isRowDisabled,
  isRowSelected,
  isRowCollapsed,
  isNested,
  getLinkTo,
  onRowClick,
  onToggleRowCollapse,
  multiSelectedRows,
  renderSubTable,
  rows,
  actions,
}: AutoTableBodyProps<D>) {
  return (
    <TableBody>
      {rows.map((row) => (
        <AutoTableRow
          key={row.id}
          row={row}
          table={table}
          isRowDisabled={isRowDisabled}
          isRowSelected={isRowSelected}
          isRowCollapsed={isRowCollapsed}
          onToggleRowCollapse={onToggleRowCollapse}
          isNested={isNested}
          getLinkTo={getLinkTo}
          onRowClick={onRowClick}
          isMultiSelected={multiSelectedRows?.[row.id]}
          renderSubTable={renderSubTable}
        />
      ))}
      {actions && (
        <TableRow>
          <TableCellBuffer side="leading" />
          <TableCellState side="leading" />
          <TableCell colSpan={1000}>{actions}</TableCell>
          <TableCellState side="trailing" />
          <TableCellBuffer side="trailing" />
        </TableRow>
      )}
    </TableBody>
  );
}

// react-table throws errors when getting row model if the sort/filter
// state is invalid. This is probably just because of the global URL-based
// search state becoming outdated, so we try resetting the filter and sort
// state and getting the rows again once.
const getRows = <D,>(table: ReactTable<D>) => {
  try {
    return table.getRowModel()?.rows ?? [];
  } catch (err) {
    table.resetSorting();
    table.resetGlobalFilter();
    table.resetColumnFilters();
    return table.getRowModel()?.rows ?? [];
  }
};

type CommonAutoTableProps<D> = {
  actions?: React.ReactNode;
  globalFilter?: string;
  globalFilterFn?: FilterFnOption<D>;
  sortingState?: SortingState;
  onChangeSortingState?: Dispatch<SetStateAction<SortingState>>;
  data: D[];
  columns: ColumnDef<D, any>[];
  getLinkTo?: GetLinkTo<D>;
  isRowDisabled?: IsRowDisabled<D>;
  isRowSelected?: IsRowSelected<D>;
  onRowDeselect?: OnRowDeselect<D>;
  enableSorting?: boolean;
  enableRowSelection?: boolean;
  enableMultiRowSelection?: boolean;
  /**
   * Does not need to end with `.csv`, it will be added automatically.
   */
  exportToCSVFilename?: string;
  onRowClick?: OnRowClick<D>;
  multiSelectedRows?: RowSelectionState;
  onRowMultiSelectionChange?: OnChangeFn<RowSelectionState>;
  getRowId?: (originalRow: D, index: number, parent?: Row<D>) => string;
  size?: 'auto' | 'small' | 'large';
  isNested?: boolean;
  renderSubTable?: (props: SubAutoTableProps<D>) => JSX.Element;
  subTableCollapsedByDefault?: boolean;
};

type AutoTableProps<D> = CommonAutoTableProps<D> &
  (
    | { isVirtual?: false; tableContainerRef?: false }
    | {
        isVirtual: true;
        tableContainerRef: React.RefObject<HTMLDivElement>;
        renderSubTable?: false;
      }
  );

export interface ExportableToCSV {
  exportToCSV: () => void;
}

function defaultColumnCanGlobalFilter<D>(c: Column<D>): boolean {
  return c.columnDef.enableGlobalFilter ?? true;
}

function AutoTableInner<D>(
  {
    actions,
    sortingState,
    globalFilter,
    globalFilterFn = defaultGlobalFilterFn,
    columns,
    data,
    getLinkTo,
    isRowDisabled,
    isRowSelected,
    onChangeSortingState,
    onRowClick,
    enableSorting = true,
    enableMultiRowSelection = false,
    exportToCSVFilename = 'export',
    onRowMultiSelectionChange,
    multiSelectedRows = {},
    getRowId,
    isNested,
    renderSubTable,
    subTableCollapsedByDefault = true,
    isVirtual = undefined,
    tableContainerRef = undefined,
  }: AutoTableProps<D>,
  ref: ForwardedRef<ExportableToCSV>,
) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: enableSorting ? getSortedRowModel() : undefined,
    enableGlobalFilter: true,
    getColumnCanGlobalFilter: defaultColumnCanGlobalFilter,
    globalFilterFn,
    onSortingChange: onChangeSortingState,
    enableMultiRowSelection,
    onRowSelectionChange: onRowMultiSelectionChange,
    getRowId,
    state: {
      globalFilter,
      sorting: sortingState,
      rowSelection: multiSelectedRows,
    },
  });

  const csvConfig = useMemo(() => {
    const csvOptions = {
      fieldSeparator: ',',
      quoteStrings: true,
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      filename: exportToCSVFilename.replace(/\.csv$/, ''),
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    return mkConfig(csvOptions);
  }, [exportToCSVFilename]);

  const handleExportToCSV = useCallback(() => {
    const { rows } = table.getRowModel();
    const csvData = rows.map((row) =>
      Object.fromEntries(
        row
          .getAllCells()
          .map((cell) => [
            cell.column.columnDef.header &&
            ['string', 'number'].includes(typeof cell.column.columnDef.header)
              ? cell.column.columnDef.header
              : cell.column.columnDef?.meta?.tooltip?.contents ?? '',
            cell.getValue(),
          ]),
      ),
    );

    const csv = generateCsv(csvConfig)(csvData);
    download(csvConfig)(csv);
  }, [csvConfig, table]);

  useImperativeHandle(ref, () => ({
    exportToCSV: handleExportToCSV,
  }));

  const rows = getRows(table);

  const hasSubTable = renderSubTable;
  const [collapsed, setCollapsed] = useState(subTableCollapsedByDefault);
  const initCollapsedState = useMemo(
    () => (subTableCollapsedByDefault ? new Set(rows.map((row) => row.id)) : new Set()),
    [rows, subTableCollapsedByDefault],
  );
  const [collapsedRowSet, setCollapsedRowSet] = useState(initCollapsedState);
  const onToggleRowCollapse = useCallback(
    (row: Row<D>) => {
      const newCollapsedSet = new Set([...collapsedRowSet]);
      if (collapsedRowSet.has(row.id)) {
        newCollapsedSet.delete(row.id);
      } else {
        newCollapsedSet.add(row.id);
      }
      setCollapsedRowSet(newCollapsedSet);
    },
    [collapsedRowSet],
  );
  const isRowCollapsed = useCallback(
    (row: Row<D>) => collapsedRowSet.has(row.id),
    [collapsedRowSet],
  );

  // TODO not functional b/c the ref must be defined outside of this component render. May do later
  // const onScrollBottomReachedInternal = useCallback(
  //   (containerRefElement?: HTMLDivElement | null) => {
  //     if (containerRefElement && onScrollBottomReached) {
  //       const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
  //       // somewhat arbitrary '100' bottom scroll zone activation area
  //       if (scrollHeight - scrollTop - clientHeight < 100) {
  //         onScrollBottomReached(containerRefElement);
  //       }
  //     }
  //   },
  //   [onScrollBottomReached],
  // );

  // const debouncedOnScroll = useDebouncedCallback(() => {
  //   if (tableContainerRef?.current && onScrollBottomReached) {
  //     onScrollBottomReachedInternal(tableContainerRef?.current);
  //   }
  // }, 150);

  return (
    <Table isNested={isNested}>
      <TableHead>
        {table.getHeaderGroups().map((headerGroup) => (
          <TableHeadRow key={headerGroup.id}>
            <TableCellBuffer side="leading" head />
            <TableCellState side="leading" head />
            {hasSubTable && (
              <TableHeadCell
                collapsable
                onClick={() => {
                  setCollapsed(!collapsed);
                  if (collapsed) {
                    setCollapsedRowSet(new Set());
                  } else {
                    setCollapsedRowSet(new Set([...rows.map((row) => row.id)]));
                  }
                }}
                collapsed={collapsed}
              />
            )}
            {headerGroup.headers.map((header) => {
              const showToolTip = header.column.columnDef.meta?.tooltip !== undefined;

              const headCell = (
                <TableHeadCell
                  {...(showToolTip ? {} : { key: header.id })}
                  sortDirection={header.column.getIsSorted()}
                  onClick={header.column.getToggleSortingHandler()}
                  {...getSizeProps(table, header.column)}
                  internal={header.column.columnDef.meta?.internal}
                  isLeading={header.column.columnDef.meta?.isLeading}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(header.column.columnDef.header, header.getContext())}
                </TableHeadCell>
              );

              return showToolTip ? (
                <Tooltip
                  key={header.id}
                  contents={header.column.columnDef.meta!.tooltip!.contents}
                  side="top"
                >
                  {headCell}
                </Tooltip>
              ) : (
                headCell
              );
            })}
            <TableCellState side="trailing" head />
            <TableCellBuffer side="trailing" head />
          </TableHeadRow>
        ))}
      </TableHead>
      {isVirtual && tableContainerRef ? (
        <VirtualAutoTableBody
          table={table}
          rows={rows}
          tableContainerRef={tableContainerRef}
          isRowDisabled={isRowDisabled}
          isRowSelected={isRowSelected}
          isRowCollapsed={isRowCollapsed}
          onToggleRowCollapse={onToggleRowCollapse}
          isNested={isNested}
          getLinkTo={getLinkTo}
          onRowClick={onRowClick}
          multiSelectedRows={multiSelectedRows}
          renderSubTable={renderSubTable}
          actions={actions}
        />
      ) : (
        <AutoTableBody
          table={table}
          rows={rows}
          isRowDisabled={isRowDisabled}
          isRowSelected={isRowSelected}
          isRowCollapsed={isRowCollapsed}
          onToggleRowCollapse={onToggleRowCollapse}
          isNested={isNested}
          getLinkTo={getLinkTo}
          onRowClick={onRowClick}
          multiSelectedRows={multiSelectedRows}
          renderSubTable={renderSubTable}
          actions={actions}
        />
      )}
    </Table>
  );
}

// Must add type assertion to carry through the generic argument to AutoTableProps
// https://fettblog.eu/typescript-react-generic-forward-refs/#option-1%3A-type-assertion
export const AutoTable = React.forwardRef(AutoTableInner) as <D extends any>(
  props: AutoTableProps<D> & { ref?: ForwardedRef<ExportableToCSV> },
) => ReturnType<typeof AutoTableInner>;
