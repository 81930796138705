import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useState } from 'react';

export interface UseStorageOptions {
  poll?: boolean;
  logError?: (error: any) => void;
}

export function useStorage<T>(
  storage: typeof window.localStorage | typeof window.sessionStorage,
  key: string,
  initialValue: T,
  { poll, logError }: UseStorageOptions = {
    poll: false,
    logError: undefined,
  },
): [T, (value: T) => void] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = storage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      logError?.(error);
      return initialValue;
    }
  });

  const setValue: Dispatch<SetStateAction<T>> = useCallback(
    (value) => {
      try {
        const valueToStore = value instanceof Function ? value(storedValue) : value;

        setStoredValue(valueToStore);

        storage.setItem(key, JSON.stringify(valueToStore));
      } catch (error) {
        logError?.(error);
      }
    },
    [key, storedValue, logError, storage],
  );

  useEffect(() => {
    if (!poll) {
      return;
    }

    const pollingFn = () => {
      const item = storage.getItem(key);
      const parsedItem = item ? JSON.parse(item) : null;

      if (parsedItem === null) {
        return;
      }

      if (JSON.stringify(storedValue) !== JSON.stringify(parsedItem)) {
        setStoredValue(parsedItem);
      }

      setTimeout(pollingFn, 250);
    };

    setTimeout(pollingFn, 250);
  }, [poll, storedValue, key, storage]);

  return [storedValue, setValue];
}
