import React, { createContext, useContext } from 'react';

import type { GutterProp } from '../../common/sizing';
import type { InternalProp } from '../../utilities/useInternal';
import { sizing } from '../../common/sizing';
import FeatureBadge from '../../formatting/FeatureBadge/FeatureBadge';
import { colors, darkThemeSelector, fontWeights, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { Small } from '../../text/Small';
import { Text } from '../../text/Text';
import { InternalProvider, useInternal } from '../../utilities/useInternal';

export type SummaryListPropSize = 'x-small' | 'medium';
type SummaryListSize = SummaryListPropSize;
const SummaryListSizeContext = createContext<SummaryListSize | undefined>(undefined);
export const SummaryListSizeProvider = SummaryListSizeContext.Provider;
export const useSummaryListSize = (
  controlledValue?: SummaryListSize,
  defaultValue: SummaryListSize = 'medium',
) => {
  const summaryListSize = useContext(SummaryListSizeContext);
  return controlledValue ?? summaryListSize ?? defaultValue;
};

export type SummaryListPairs = {
  label: React.ReactNode;
  value: React.ReactNode;
  internal?: boolean;
};

const SummaryListKeyLabel = styled(Small, {
  display: 'flex',
  minWidth: 'fit-content',
  maxWidth: '100%',
  color: '$$keyColor',
  fontWeight: fontWeights.bold,
});

const SummaryListKeyInternal = styled('div', {
  display: 'flex',

  '@desktop': {
    marginTop: '-$2',
    marginBottom: '-$2',
  },
});

const SummaryListKeyContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$4',
  width: '100%',
  padding: '$2 0',
});

export type SummaryListKeyProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Boolean to show internal badge.
   */
  internal?: InternalProp;
};

export function SummaryListKey({ children, internal, ...remaining }: SummaryListKeyProps) {
  const isInternal = useInternal(internal);
  return (
    <SummaryListKeyContainer {...remaining}>
      <SummaryListKeyLabel>{children}</SummaryListKeyLabel>
      {isInternal && (
        <SummaryListKeyInternal>
          <FeatureBadge arrangement="icon-only" type="internal" size="small" />
        </SummaryListKeyInternal>
      )}
    </SummaryListKeyContainer>
  );
}

const SummaryListValueContainer = styled(Text, {
  display: 'flex',
  width: '100%',
  maxWidth: '$700',
  wordBreak: 'break-word',
  color: '$$valueColor',

  variants: {
    size: {
      'x-small': {
        '@notDesktop': {
          minHeight: '$20',
          fontSize: '$14',
          lineHeight: '$20',
        },

        '@desktop': {
          minHeight: '$16',
          fontSize: '$12',
          lineHeight: '$16',
        },
      },
      medium: {
        '@notDesktop': {
          minHeight: '$24',
          fontSize: '$16',
          lineHeight: '$24',
        },

        '@desktop': {
          minHeight: '$20',
          fontSize: '$14',
          lineHeight: '$20',
        },
      },
    },
  },
});

type SummaryListValueProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Set the size of the summary list.
   */
  size?: SummaryListPropSize;
};

export function SummaryListValue({ children, size, ...remaining }: SummaryListValueProps) {
  const summaryListSize = useSummaryListSize(size, 'medium');
  return (
    <SummaryListValueContainer size={summaryListSize} {...remaining}>
      {children}
    </SummaryListValueContainer>
  );
}

export const SummaryListRowContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  $$keyColor: colors.bodyNeutralLight,
  $$valueColor: colors.headingNeutralLight,

  [darkThemeSelector]: {
    $$keyColor: colors.bodyNeutralDark,
    $$valueColor: colors.headingNeutralDark,
  },

  variants: {
    variant: {
      negative: {
        $$keyColor: colors.bodyNegativeLight,
        $$valueColor: colors.headingNegativeLight,

        [darkThemeSelector]: {
          $$keyColor: colors.bodyNegativeDark,
          $$valueColor: colors.headingNegativeDark,
        },
      },
      attention: {
        $$keyColor: colors.bodyAttentionLight,
        $$valueColor: colors.headingAttentionLight,

        [darkThemeSelector]: {
          $$keyColor: colors.bodyAttentionDark,
          $$valueColor: colors.headingAttentionDark,
        },
      },
    },
  },
});

export type SummaryListRowProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Boolean to show internal badge.
   */
  internal?: boolean;
  /**
   * Set if the row has a special variant like `attention` or `negative` to highlight an issue.
   */
  variant?: 'attention' | 'negative';
};

export function SummaryListRow({ children, internal, variant, ...remaining }: SummaryListRowProps) {
  const isInternal = useInternal(internal);
  return (
    <InternalProvider value={isInternal}>
      <SummaryListRowContainer variant={variant} {...remaining}>
        {children}
      </SummaryListRowContainer>
    </InternalProvider>
  );
}

const SummaryListGroupLabel = styled(Body, {
  display: 'flex',
  alignItems: 'center',
  gap: '$6',
  fontWeight: fontWeights.bold,

  '&::after': {
    content: '',
    flex: 1,
    height: '$1',
    backgroundColor: colors.strokeNeutralLight,

    [darkThemeSelector]: {
      backgroundColor: colors.strokeNeutralDark,
    },
  },
});

const SummaryListGroupItems = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$12',
  width: '100%',
});

const SummaryListGroupContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$8',
  width: '100%',
});

export type SummaryListGroupProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Boolean to show internal badge.
   */
  internal?: boolean;
  /**
   * Provide a label for the group with `label`.
   */
  label?: React.ReactNode;
  /**
   * Can pass in label-value pairs for the rows.
   */
  pairs?: SummaryListPairs[];
  /**
   * Set the size of the summary list.
   */
  size?: SummaryListPropSize;
};

export function SummaryListGroup({
  children,
  internal,
  label,
  pairs,
  size,
  ...remaining
}: SummaryListGroupProps) {
  const isInternal = useInternal(internal);
  const summaryListSize = useSummaryListSize(size, 'medium');
  return (
    <SummaryListGroupContainer {...remaining}>
      <SummaryListGroupLabel>{label}</SummaryListGroupLabel>
      <SummaryListGroupItems>
        {children}
        {pairs &&
          pairs
            .filter((pair) => !!pair.label && !!pair.value)
            .map((pair, index) => {
              const key = index;
              return (
                <SummaryListRow
                  internal={isInternal || pair.internal}
                  key={`summaryList-${key}`}
                  {...pair}
                >
                  {pair.label && <SummaryListKey>{pair.label}</SummaryListKey>}
                  {pair.value && (
                    <SummaryListValue size={summaryListSize}>{pair.value}</SummaryListValue>
                  )}
                </SummaryListRow>
              );
            })}
      </SummaryListGroupItems>
    </SummaryListGroupContainer>
  );
}

export const SummaryListContainer = styled('div', {
  gap: sizing.contentSides,
  width: '100%',

  variants: {
    direction: {
      column: {
        display: 'flex',
        flexDirection: 'column',
      },
      row: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax($148, 1fr))',
        gridTemplateRows: 'repeat(auto-fit, minmax(min-content, 0))',
        gridAutoFlow: 'row dense',

        [`& ${SummaryListRowContainer}`]: {
          width: 'auto',
        },

        [`& ${SummaryListKeyLabel}`]: {
          whiteSpace: 'nowrap',
        },
      },
    },
    gutter: {
      all: {
        padding: sizing.contentSquish,
      },
      vertical: {
        padding: sizing.contentEndsOnly,
      },
      horizontal: {
        padding: sizing.contentSidesOnly,
      },
      top: {
        paddingTop: sizing.contentEnds,
      },
      right: {
        paddingRight: sizing.contentSides,
      },
      bottom: {
        paddingBottom: sizing.contentEnds,
      },
      left: {
        paddingLeft: sizing.contentSides,
      },
      none: {},
    },
    size: {
      'x-small': {},
      medium: {},
    },
  },
});

export type SummaryListProps = {
  /**
   * Pass in any content as `children`.
   */
  children?: React.ReactNode;
  /**
   * Set the direction of the summary list items.
   */
  direction?: 'column' | 'row';
  /**
   * Set whether there should be a gutter or not around the children.
   */
  gutter?: GutterProp;
  /**
   * Boolean to show internal badge.
   */
  internal?: boolean;
  /**
   * Can pass in label-value pairs for the rows.
   */
  pairs?: SummaryListPairs[];
  /**
   * Set the size of the summary list.
   */
  size?: SummaryListPropSize;
};

export function SummaryList({
  children,
  direction = 'column',
  internal,
  gutter = 'all',
  pairs,
  size = 'medium',
  ...remaining
}: SummaryListProps) {
  return (
    <SummaryListSizeProvider value={size}>
      <InternalProvider value={internal}>
        <SummaryListContainer direction={direction} gutter={gutter} size={size} {...remaining}>
          {children}
          {pairs &&
            pairs
              .filter((pair) => !!pair.label && !!pair.value)
              .map((pair, index) => {
                const key = index;
                return (
                  <SummaryListRow internal={pair.internal} key={`summaryList-${key}`} {...pair}>
                    {pair.label && <SummaryListKey>{pair.label}</SummaryListKey>}
                    {pair.value && <SummaryListValue size={size}>{pair.value}</SummaryListValue>}
                  </SummaryListRow>
                );
              })}
        </SummaryListContainer>
      </InternalProvider>
    </SummaryListSizeProvider>
  );
}
