import { freeze, immerable } from 'immer';

import type { ct } from '../schema/config';
import type { MeterV2WirelessServiceSet } from './MeterV2WirelessServiceSet';

export class MeterV2WirelessTag {
  [immerable] = true;

  private constructor(
    public name: string,
    public json: ct.MeterV2WirelessTag,
  ) {}

  static fromJSON(name: string, json: ct.MeterV2WirelessTag): MeterV2WirelessTag {
    return freeze(new MeterV2WirelessTag(name, json), true);
  }

  toJSON(serviceSets: MeterV2WirelessServiceSet[]): ct.MeterV2WirelessTag {
    return {
      ...this.json,
      'service-sets':
        serviceSets.length > 0
          ? Object.fromEntries(
              serviceSets.map((serviceSet) => [serviceSet.ssid, serviceSet.toJSON()]),
            )
          : undefined,
    };
  }
}
