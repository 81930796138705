import type { ManufacturerIconName } from '@meterup/atto';
import ouiData from 'oui-data';
import { match } from 'ts-pattern';

import type { NetworkClient } from '../gql/graphql';

export const isOnline = (c: Pick<NetworkClient, 'lastSeen'>): boolean => {
  if (c.lastSeen) {
    const dateLessFive = new Date();
    const itemDate = new Date(c.lastSeen);

    dateLessFive.setMinutes(dateLessFive.getMinutes() - 5);

    return itemDate >= dateLessFive;
  }

  return false;
};

export const isOffline = (c: Pick<NetworkClient, 'lastSeen'>): boolean => !isOnline(c);

export const isGuest = (c: Pick<NetworkClient, 'vlan'>) => c.vlan === 'guest';

export const lookupMACAddressOUI = (macAddress: string): string | undefined => {
  const stripped = macAddress
    .replace(/[^0-9a-f]/gi, '')
    .substring(0, 6)
    .toUpperCase();

  const macOUIData: string | undefined =
    stripped in ouiData ? ouiData[stripped as keyof typeof ouiData] : undefined;

  return macOUIData?.substring(0, macOUIData.indexOf('\n'));
};

export const clientNameOrNull = (c: Pick<NetworkClient, 'alias' | 'clientName'>) =>
  (c.alias || (c.clientName !== '*' ? c.clientName : null)) ?? null;

export const isMeterManufacturer = (manufacturer: string) =>
  manufacturer.toLowerCase().includes('meter, inc.');

export const getManufacturerIconName = (manufacturer: string): ManufacturerIconName =>
  match(manufacturer.toLowerCase())
    .when(
      (v) => v.includes('amazon'),
      () => 'amazon' as const,
    )
    .when(
      (v) => v.includes('apple'),
      () => 'apple' as const,
    )
    .when(
      (v) => v.includes('asus'),
      () => 'asus' as const,
    )
    .when(
      (v) => v.includes('dell'),
      () => 'dell' as const,
    )
    .when(
      (v) => v.includes('ecobee'),
      () => 'ecobee' as const,
    )
    .when(
      (v) => v.includes('google'),
      () => 'google' as const,
    )
    .when(
      (v) => v.includes('hp'),
      () => 'hp' as const,
    )
    .when(
      (v) => v.includes('hewlett'),
      () => 'hp' as const,
    )
    .when(
      (v) => v.includes('packard'),
      () => 'hp' as const,
    )
    .when(
      (v) => v.includes('ibm'),
      () => 'ibm' as const,
    )
    .when(
      (v) => v.includes('intel'),
      () => 'intel' as const,
    )
    .when(
      (v) => v.includes('lenovo'),
      () => 'lenovo' as const,
    )
    .when(
      (v) => v.includes('lg'),
      () => 'lg' as const,
    )
    .when(
      (v) => v.includes('logitech'),
      () => 'logitech' as const,
    )
    .when(
      (v) => v.includes('microsoft'),
      () => 'microsoft' as const,
    )
    .when(
      (v) => v.includes('nest'),
      () => 'nest' as const,
    )
    .when(
      (v) => v.includes('philips'),
      () => 'philips' as const,
    )
    .when(
      (v) => v.includes('samsung'),
      () => 'samsung' as const,
    )
    .when(
      (v) => v.includes('shure'),
      () => 'shure' as const,
    )
    .when(
      (v) => v.includes('sonos'),
      () => 'sonos' as const,
    )
    .otherwise(() => 'unknown' as const);
