import type { api } from '@meterup/proto';
import {
  Badge,
  Section,
  SectionContent,
  SectionHeader,
  SummaryList,
  SummaryListKey,
  SummaryListRow,
  SummaryListValue,
} from '@meterup/atto';
import React from 'react';

import { isDefined } from '../helpers/isDefined';
import { DisabledBadge, EnabledBadge } from './badges';

export interface RadioDetailsProps {
  title: string;
  radio?: api.AccessPointRadioConfig | null;
}

export function EnabledRadioDetailsContent({ radio }: { radio: api.AccessPointRadioConfig }) {
  return (
    <>
      <SummaryListRow>
        <SummaryListKey>Status</SummaryListKey>
        <SummaryListValue>
          <EnabledBadge arrangement="leading-icon" icon="checkmark-circle" />
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>Channel</SummaryListKey>
        <SummaryListValue>
          <Badge size="small">{radio.channel}</Badge>
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>Width</SummaryListKey>
        <SummaryListValue>
          <Badge size="small">{radio.channel_width_MHz}</Badge>
        </SummaryListValue>
      </SummaryListRow>
      <SummaryListRow>
        <SummaryListKey>Power</SummaryListKey>
        <SummaryListValue>
          <Badge size="small">{radio.tx_power_dBm}</Badge>
        </SummaryListValue>
      </SummaryListRow>
    </>
  );
}

export function DisabledRadioDetailsContent() {
  return (
    <SummaryListRow>
      <SummaryListKey>Status</SummaryListKey>
      <SummaryListValue>
        <DisabledBadge arrangement="leading-icon" icon="cross-circle" />
      </SummaryListValue>
    </SummaryListRow>
  );
}

export function WirelessBandDetails({ title, radio }: RadioDetailsProps) {
  return (
    <Section relation="stacked">
      <SectionHeader icon="wifi" heading={title} />
      <SectionContent>
        <SummaryList>
          {isDefined(radio) && radio.enabled ? (
            <EnabledRadioDetailsContent radio={radio} />
          ) : (
            <DisabledRadioDetailsContent />
          )}
        </SummaryList>
      </SectionContent>
    </Section>
  );
}
