/* eslint-disable react/button-has-type,react/prop-types */
import type { ButtonHTMLAttributes } from 'react';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { ShortcutKeys } from '../../formatting/Shortcut/Shortcut';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import type { ControlSize, ControlVariant } from '../shared/types';
import { Icon } from '../../assets/Icon/Icon';
import { LoadingIcon } from '../../assets/LoadingIcon/LoadingIcon';
import { useControlSize } from '../../common/control_size';
import { useControlVariant } from '../../common/control_variant';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import Shortcut from '../../formatting/Shortcut/Shortcut';
import { darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Body } from '../../text/Body';
import { Large } from '../../text/Large';
import { Small } from '../../text/Small';
import { useViewport } from '../../utilities/useViewport';
import { selectors, transitions } from '../shared/styles';
import {
  destructiveDisabledStyles,
  destructiveEnabledStyles,
  iconColor,
  internalDisabledStyles,
  internalEnabledStyles,
  labelColor,
  loadingIconColor,
  primaryDisabledStyles,
  primaryEnabledStyles,
  secondaryDisabledStyles,
  secondaryEnabledStyles,
} from './button_styles';

const ButtonIcon = styled(Icon, {
  color: iconColor,
  transition: transitions.control,
  opacity: 1,
  width: '$$iconSize',
  height: '$$iconSize',
  variants: {
    isLoading: {
      true: {
        opacity: 0,
      },
    },
  },
});

const ButtonLoadingIcon = styled(LoadingIcon, {
  color: loadingIconColor,
  transition: transitions.control,
});

const ButtonLabel = styled('span', {
  display: 'flex',
  fontWeight: fontWeights.bold,
  transition: transitions.control,
  // NOTE: Override base Text colors
  color: `${labelColor} !important`,

  [darkThemeSelector]: {
    color: `${labelColor} !important`,
  },

  variants: {
    isLoading: {
      true: {
        opacity: 0,
      },
      false: {
        opacity: 1,
      },
    },
    arrangement: {
      'leading-icon': {},
      'leading-label': {},
    },
    condense: {
      true: {},
      false: {},
    },
    labelAlign: {
      start: {
        justifyContent: 'flex-start',
        width: '100%',
      },
      center: {},
      end: {
        justifyContent: 'flex-end',
        width: '100%',
      },
    },
  },
});

export const ButtonContainer = styled('button', {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$$buttonGap',
  minWidth: 'min-content',
  paddingX: '$$paddingX',
  paddingY: '$$paddingY',
  opacity: 1,
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  userSelect: 'none',
  transition: transitions.control,

  [selectors.focus]: {
    outline: 'none',
  },

  variants: {
    arrangement: {
      'hidden-label': {},
      'leading-icon': {},
      'leading-label': {},
    },
    size: {
      'x-small': {
        $$buttonGap: '$space$2',
        minHeight: '$20',
        $$paddingX: '$space$4',
        $$paddingY: '$space$2',
        $$iconSize: '$space$12',
        borderRadius: '$6',
      },
      small: {
        $$buttonGap: '$space$4',
        minHeight: '$24',
        $$paddingX: '$space$6',
        $$paddingY: '$space$2',
        $$iconSize: '$space$12',
        borderRadius: '$8',
      },
      medium: {
        $$buttonGap: '$space$4',
        minHeight: '$28',
        $$paddingX: '$space$8',
        $$paddingY: '$space$4',
        $$iconSize: '$space$12',
        borderRadius: '$8',
      },
      large: {
        $$buttonGap: '$space$6',
        minHeight: '$36',
        $$paddingX: '$space$12',
        $$paddingY: '$space$4',
        $$iconSize: '$space$12',
        borderRadius: '$10',
      },
      'x-large': {
        $$buttonGap: '$space$8',
        minHeight: '$44',
        $$paddingX: '$space$20',
        $$paddingY: '$space$6',
        $$iconSize: '$space$12',
        borderRadius: '$12',
      },
    },
    internal: {
      true: {},
      false: {},
    },
    isDisabled: {
      true: {
        cursor: 'not-allowed',
        opacity: 0.5,
      },
      false: {},
    },
    invalid: {
      true: {},
      false: {},
    },
    variant: {
      destructive: {},
      primary: {},
      secondary: {},
    },
  },
  compoundVariants: [
    // Icon only
    {
      arrangement: 'hidden-label',
      size: 'x-small',
      css: {
        minWidth: '$24',
      },
    },
    {
      arrangement: 'hidden-label',
      size: 'small',
      css: {
        minWidth: '$28',
      },
    },
    {
      arrangement: 'hidden-label',
      size: 'medium',
      css: {
        minWidth: '$32',
      },
    },
    {
      arrangement: 'hidden-label',
      size: 'large',
      css: {
        minWidth: '$40',
      },
    },
    {
      arrangement: 'hidden-label',
      size: 'x-large',
      css: {
        minWidth: '$48',
      },
    },
    // Destructive
    {
      variant: 'destructive',
      isDisabled: false,
      css: {
        '&[type=button], &[type=submit]': destructiveEnabledStyles,
        '&': destructiveEnabledStyles,
      },
    },
    {
      variant: 'destructive',
      isDisabled: true,
      css: destructiveDisabledStyles,
    },

    // Primary
    {
      variant: 'primary',
      isDisabled: false,
      css: {
        '&[type=button], &[type=submit]': primaryEnabledStyles,
        '&': primaryEnabledStyles,
      },
    },
    {
      variant: 'primary',
      isDisabled: true,
      css: primaryDisabledStyles,
    },

    // Secondary
    {
      variant: 'secondary',
      isDisabled: false,
      css: secondaryEnabledStyles,
    },
    {
      variant: 'secondary',
      isDisabled: true,
      css: secondaryDisabledStyles,
    },

    // Internal
    {
      internal: true,
      isDisabled: false,
      css: internalEnabledStyles,
    },
    {
      internal: true,
      isDisabled: true,
      css: internalDisabledStyles,
    },

    {
      isDisabled: false,
      invalid: true,
      css: {
        boxShadow: shadows.fieldErrorLight,
        '&:hover:not(:focus)': {
          boxShadow: shadows.fieldErrorLight,
        },
        [darkThemeSelector]: {
          boxShadow: shadows.fieldErrorDark,
          '&:hover:not(:focus)': {
            boxShadow: shadows.fieldErrorDark,
          },
        },
      },
    },
  ],
});

const ButtonLoadingContainer = styled('div', {
  position: 'absolute',
  inset: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ButtonContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$$buttonGap',

  variants: {
    arrangement: {
      'hidden-label': {},
      'leading-icon': {
        flexDirection: 'row-reverse',
      },
      'leading-label': {},
    },
    labelAlign: {
      start: {
        justifyContent: 'flex-start',
        width: '100%',
      },
      center: {},
      end: {
        justifyContent: 'flex-end',
        width: '100%',
      },
    },
  },
});

const ButtonDirectionArrow = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  flexBasis: 0,
});

const ButtonMenuArrow = styled(ButtonIcon, {
  height: '$10',
  width: '$10',
});

const ButtonPreviousArrow = styled(ButtonDirectionArrow, {
  justifyContent: 'flex-start',
});

const ButtonNextArrow = styled(ButtonDirectionArrow, {
  justifyContent: 'flex-end',
});

export type ButtonArrangementProp = 'hidden-label' | 'leading-icon' | 'leading-label';
export type ButtonMenuArrowProp = 'dropdown' | 'select';
export type ButtonDirectionProp = 'next' | 'previous';
export type ButtonLabelAlignProp = 'start' | 'center' | 'end';
export type ButtonSizeProp = ControlSize;
export type ButtonTypeProp = 'button' | 'submit' | 'reset';
export type ButtonVariantProp = ControlVariant;

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  arrangement?: ButtonArrangementProp;
  children: React.ReactNode;
  condense?: boolean;
  direction?: ButtonDirectionProp;
  disabled?: boolean;
  invalid?: boolean;
  form?: string;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  internal?: boolean;
  menuArrow?: ButtonMenuArrowProp;
  labelAlign?: ButtonLabelAlignProp;
  loading?: boolean;
  /**
   * Set shortcut keys for the button.
   */
  shortcut?: ShortcutKeys[];
  size?: ButtonSizeProp;
  type?: ButtonTypeProp;
  /**
   * Set the most appropriate variant of the component for your use.
   */
  variant?: ButtonVariantProp;
  /**
   * Set a width.
   */
  width?: string;
  /**
   * Set a maximum width.
   */
  maxWidth?: string;
  /**
   * Set a minimum width.
   */
  minWidth?: string;
}

function ButtonInner<Tag extends React.ElementType>(
  {
    'aria-label': ariaLabel,
    arrangement = 'leading-label',
    children,
    condense,
    direction,
    disabled = false,
    icon,
    internal,
    menuArrow,
    labelAlign,
    loading = false,
    invalid = false,
    shortcut,
    size,
    width,
    maxWidth,
    minWidth,
    variant,
    type = 'button',
    ...rest
  }: PolymorphicComponentProps<Tag, ButtonProps>,
  ref: PolymorphicRef<Tag>,
) {
  const { breakpoint } = useViewport();
  const controlSize = useControlSize(size, 'medium');
  const controlVariant = useControlVariant(variant, 'primary');

  // Disable the button during its loading state
  const isDisabled = disabled || loading;

  const hiddenLabel = (condense && breakpoint !== 'desktop') || arrangement === 'hidden-label';

  const labelElement = (buttonSize: ControlSize) => {
    if (buttonSize === 'x-small' || buttonSize === 'small') {
      return Small;
    }
    if (buttonSize === 'medium' || buttonSize === 'large') {
      return Body;
    }
    return Large;
  };

  const buttonRender = (
    <ButtonContainer
      {...(rest as object)}
      type={type}
      ref={ref}
      aria-label={
        ariaLabel || (arrangement === 'hidden-label' || condense ? `${children}` : undefined)
      }
      arrangement={arrangement}
      variant={controlVariant}
      size={controlSize}
      internal={internal}
      isDisabled={isDisabled}
      disabled={isDisabled}
      invalid={invalid}
      style={{
        width,
        maxWidth,
        minWidth,
      }}
    >
      {loading && (
        <ButtonLoadingContainer>
          <ButtonLoadingIcon />
        </ButtonLoadingContainer>
      )}
      {direction === 'previous' && (
        <ButtonPreviousArrow>
          <ButtonIcon isLoading={loading} icon="arrow-left" />
        </ButtonPreviousArrow>
      )}
      <ButtonContent arrangement={arrangement} labelAlign={labelAlign}>
        {!hiddenLabel && (
          <ButtonLabel isLoading={loading} labelAlign={labelAlign} as={labelElement(controlSize)}>
            {children}
          </ButtonLabel>
        )}
        {icon && <ButtonIcon isLoading={loading} icon={icon} />}
      </ButtonContent>
      {shortcut && (
        <Shortcut
          control
          internal={internal}
          variant={controlVariant === 'secondary' ? 'neutral' : controlVariant}
          keys={shortcut}
        />
      )}
      {menuArrow && (
        <ButtonMenuArrow
          isLoading={loading}
          icon={menuArrow === 'dropdown' ? 'chevron-down' : 'arrows-vertical'}
        />
      )}
      {direction === 'next' && (
        <ButtonNextArrow>
          <ButtonIcon isLoading={loading} icon="arrow-right" />
        </ButtonNextArrow>
      )}
    </ButtonContainer>
  );

  if (arrangement === 'hidden-label' || (condense && breakpoint !== 'desktop')) {
    return (
      <Tooltip contents={children} sideOffset={2}>
        {buttonRender}
      </Tooltip>
    );
  }

  return buttonRender;
}

function fixedForwardRef<T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactElement,
): (props: P & React.RefAttributes<T>) => React.ReactElement {
  return React.forwardRef(render) as any;
}

export const Button = fixedForwardRef(ButtonInner);
