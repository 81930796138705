import type * as Polymorphic from '@radix-ui/react-polymorphic';
import React, { createContext, useContext, useEffect, useState } from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { FocusRing } from '../../common/focus_rings';
import { visuallyHiddenCSS } from '../../common/visually_hidden';
import { Badge } from '../../components/Badge/Badge';
import { Tooltip } from '../../components/Tooltip/Tooltip';
import { Button } from '../../controls/Button/Button';
import { selectors } from '../../controls/shared/styles';
import Shortcut, { SpecialKey } from '../../formatting/Shortcut/Shortcut';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Small } from '../../text/Small';
import { space } from '../../utilities/shared/sizes';
import { HStack } from '../../utilities/Stack/HStack';
import { useViewport } from '../../utilities/useViewport';
import { LayoutDock } from '../Layout/Layout';

const transitions = {
  minimize: 'all 200ms linear',
};

type DockMinimized = boolean;
const DockMinimizedContext = createContext<DockMinimized | undefined>(undefined);
export const DockMinimizedProvider = DockMinimizedContext.Provider;
export const useDockMinimized = (
  controlledValue?: DockMinimized,
  defaultValue: DockMinimized = false,
) => {
  const dockMinimized = useContext(DockMinimizedContext);
  return controlledValue ?? dockMinimized ?? defaultValue;
};

const dockDividerStyles = {
  content: '',
  position: 'absolute',
  zIndex: 10,
  right: '$8',
  left: '$8',
  display: 'flex',
  height: '$1',
  marginTop: '-0.5px',
  backgroundColor: colors.strokeNeutralLight,

  [darkThemeSelector]: {
    backgroundColor: colors.strokeNeutralDark,
  },

  '@tablet': {
    top: '-$4',
  },

  '@desktop': {
    top: '-$2',
  },
};

const DockSearchTargetIcon = styled(Icon, {
  '@notDesktop': {
    width: '$20',
    height: '$20',
  },

  '@desktop': {
    width: '$16',
    height: '$16',
  },
});

const DockSearchTargetPosition = styled('a', FocusRing, {
  position: 'relative',
  minWidth: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  borderRadius: '99em',
  strokeAll: colors.strokeNeutralLight,
  color: colors.bodyNeutralLight,
  cursor: 'pointer',

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
    strokeAll: colors.strokeNeutralDark,
  },

  [selectors.hover]: {
    backgroundColor: colors.bgApplicationLight,
    color: colors.headingNeutralLight,
    strokeAll: colors.strokeNeutralLight,
    [darkThemeSelector]: {
      backgroundColor: colors.bgApplicationDark,
      color: colors.headingNeutralDark,
      strokeAll: colors.strokeNeutralDark,
    },
  },

  '&[data-state="open"]': {
    backgroundColor: colors.bgBrandLight,
    color: colors.headingBrandLight,
    strokeAll: colors.strokeBrandLight,
    [darkThemeSelector]: {
      backgroundColor: colors.bgBrandDark,
      color: colors.headingBrandDark,
      strokeAll: colors.strokeBrandDark,
    },
  },

  '@notDesktop': {
    padding: '$8 $12',
  },

  '@desktop': {
    padding: '$6 $8',
  },

  variants: {
    selected: {
      true: {},
      false: {},
    },
    indicator: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      selected: true,
      css: {
        [`&, ${selectors.hover}`]: {
          backgroundColor: colors.bgBrandLight,
          color: colors.headingBrandLight,
          strokeAll: colors.strokeBrandLight,
          [darkThemeSelector]: {
            backgroundColor: colors.bgBrandDark,
            color: colors.headingBrandDark,
            strokeAll: colors.strokeBrandDark,
          },
        },
      },
    },
  ],
});

const DockSearchTargetContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  transition: transitions.minimize,

  '@tablet': {
    padding: '$4 $8',
  },

  '@desktop': {
    padding: '$4 $8',
  },

  variants: {
    minimized: {
      true: {
        paddingRight: 0,
        paddingLeft: 0,

        '@tablet': {
          width: '$48',
          minWidth: '$48',
        },

        '@desktop': {
          width: '$36',
          minWidth: '$36',
        },
      },
      false: {},
    },
  },
});

export interface DockSearchTargetProps {
  label: React.ReactNode;
  minimized?: boolean;
}

export const DockSearchTarget = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      as = 'a' as Tag,
      label,
      minimized,
      ...props
    }: PolymorphicComponentProps<Tag, DockSearchTargetProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => {
    const dockMinimize = useDockMinimized(minimized, false);
    return (
      <DockMinimizedProvider value={dockMinimize}>
        <Tooltip
          contents={
            <HStack align="center" spacing={space(6)}>
              <Small>Search</Small>
              <Shortcut keys={[SpecialKey.CmdOrCtrl, 'K']} />
            </HStack>
          }
          side="right"
          sideOffset={3}
        >
          <DockSearchTargetContainer minimized={dockMinimize}>
            <DockSearchTargetPosition
              as={as}
              ref={forwardedRef}
              tabIndex={0}
              aria-label={`${label}`}
              {...props}
            >
              <DockSearchTargetIcon icon="search" />
            </DockSearchTargetPosition>
          </DockSearchTargetContainer>
        </Tooltip>
      </DockMinimizedProvider>
    );
  },
) as Polymorphic.ForwardRefComponent<'a', DockSearchTargetProps>;

const DockTargetPosition = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '@notDesktop': {
    width: '$20',
    height: '$20',
  },

  '@desktop': {
    width: '$16',
    height: '$16',
  },
});

export const DockTargetIcon = styled(Icon, {
  '@notDesktop': {
    width: '$20',
    height: '$20',
  },

  '@desktop': {
    width: '$16',
    height: '$16',
  },
});

const DockTargetLabel = styled('span', {
  display: 'block',
  width: '100%',
  fontSize: '$14',
  fontWeight: fontWeights.bold,
  lineHeight: '$20',
  textAlign: 'left',
  truncate: true,
  transition: transitions.minimize,

  '@mobile': {
    ...visuallyHiddenCSS,
  },
});

const DockTargetArrow = styled(Icon, {
  transition: transitions.minimize,

  '@mobile': {
    ...visuallyHiddenCSS,
  },

  '@tablet': {
    width: '$12',
    height: '$12',
  },

  '@desktop': {
    width: '$10',
    height: '$10',
  },

  variants: {
    minimized: {
      true: {
        ...visuallyHiddenCSS,
      },
      false: {},
    },
  },
});

const DockTargetCount = styled(Badge, {
  minWidth: '22px',
  justifyContent: 'center',

  '@mobile': {
    position: 'absolute',
    top: '-6px',
    right: '-6px',
  },

  variants: {
    minimized: {
      true: {
        position: 'absolute',
        top: '-6px',
        right: '-6px',
      },
      false: {},
    },
  },
});

const DockTargetContainer = styled('a', FocusRing, {
  position: 'relative',
  minWidth: 0,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  color: colors.bodyNeutralLight,
  cursor: 'pointer',

  [darkThemeSelector]: {
    color: colors.bodyNeutralDark,
  },

  [selectors.hover]: {
    backgroundColor: colors.bgApplicationLight,
    color: colors.headingNeutralLight,
    strokeAll: colors.strokeNeutralLight,
    [darkThemeSelector]: {
      backgroundColor: colors.bgApplicationDark,
      color: colors.headingNeutralDark,
      strokeAll: colors.strokeNeutralDark,
    },
  },

  '&[data-state="open"]': {
    backgroundColor: colors.bgBrandLight,
    color: colors.headingBrandLight,
    strokeAll: colors.strokeBrandLight,
    [darkThemeSelector]: {
      backgroundColor: colors.bgBrandDark,
      color: colors.headingBrandDark,
      strokeAll: colors.strokeBrandDark,
    },
  },

  '@mobile': {
    justifyContent: 'center',
    minWidth: '$40',
    minHeight: '$40',
  },

  '@tablet': {
    gap: '$8',
    padding: '$8 $12',
  },

  '@notDesktop': {
    borderRadius: '$10',
  },

  '@desktop': {
    gap: '$6',
    minHeight: '$28',
    padding: '$4 $8',
    borderRadius: '$8',
  },

  variants: {
    selected: {
      true: {},
      false: {},
    },
    indicator: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      selected: true,
      css: {
        [`&, ${selectors.hover}`]: {
          backgroundColor: colors.bgBrandLight,
          color: colors.headingBrandLight,
          strokeAll: colors.strokeBrandLight,
          [darkThemeSelector]: {
            backgroundColor: colors.bgBrandDark,
            color: colors.headingBrandDark,
            strokeAll: colors.strokeBrandDark,
          },
        },
      },
    },
  ],
});

export interface DockTargetProps {
  arrow?: boolean;
  children?: React.ReactNode;
  count?: number;
  end?: React.ReactNode;
  icon?: IconName;
  label: React.ReactNode;
  minimized?: boolean;
  selected?: boolean;
}

export const DockTarget = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      as = 'a' as Tag,
      arrow,
      children,
      count,
      end,
      icon,
      label,
      minimized,
      selected,
      ...props
    }: PolymorphicComponentProps<Tag, DockTargetProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => {
    const dockMinimize = useDockMinimized(minimized, false);

    const dockTargetRender = (
      <DockTargetContainer
        as={as}
        ref={forwardedRef}
        tabIndex={0}
        aria-label={`${label}`}
        {...props}
        selected={selected}
      >
        <DockTargetPosition>{icon ? <DockTargetIcon icon={icon} /> : children}</DockTargetPosition>
        <DockTargetLabel>{label}</DockTargetLabel>
        {end}
        {count && (
          <DockTargetCount ends="pill" size="small" variant="negative">
            {count}
          </DockTargetCount>
        )}
        {arrow && <DockTargetArrow minimized={dockMinimize} icon="chevron-right" />}
      </DockTargetContainer>
    );

    return (
      <DockMinimizedProvider value={dockMinimize}>
        {dockMinimize ? (
          <Tooltip contents={label} side="right" sideOffset={3}>
            {dockTargetRender}
          </Tooltip>
        ) : (
          dockTargetRender
        )}
      </DockMinimizedProvider>
    );
  },
) as Polymorphic.ForwardRefComponent<'a', DockTargetProps>;

const DockMenu = styled(DockTarget, {
  gridArea: 'menu',
});

const DockMenuIconOpen = styled(Icon, {
  width: '$24',
  height: '$24',
});

const DockMenuIconClose = styled(Icon, {
  width: '$20',
  height: '$20',
});

const DockSigilContainer = styled('div', Small, {
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  userSelect: 'none',
  $$backgroundColor: colors.brand600,

  '@notDesktop': {
    width: '$24',
    minWidth: '$24',
    height: '$24',
    minHeight: '$24',
  },

  '@desktop': {
    width: '$20',
    minWidth: '$20',
    height: '$20',
    minHeight: '$20',
  },
});

const DockSigilFrame = styled('svg', {
  zIndex: 0,
  position: 'absolute',
  inset: 0,
  fill: '$$backgroundColor',
});

const DockSigilInitials = styled('div', {
  zIndex: 1,
  display: 'flex',
  color: colors.brand50,
  letterSpacing: '-0.05em',
  lineHeight: '$20',
  fontFamily: '$mono',
  fontWeight: '$bold',

  '@notDesktop': {
    fontSize: '$12',
  },

  '@desktop': {
    fontSize: '$9',
  },
});

export type DockSigilProps = {
  initials?: React.ReactNode;
};

export function DockSigil({ initials = <Icon icon="company" size={space(10)} /> }: DockSigilProps) {
  return (
    <DockSigilContainer family="monospace">
      <DockSigilFrame viewBox="0 0 20 20">
        <path d="M20 10C20 6.58894 19.7675 2.74054 18.5175 1.48688C17.2675 0.233235 12.7907 0 10.0291 0C7.26743 0 2.79072 0.233235 1.5407 1.48688C0.290696 2.74054 0 6.58894 0 10C0 13.4111 0.2907 17.2595 1.5407 18.5131C2.79072 19.7668 7.26743 20 10.0291 20C12.7907 20 17.2674 19.7668 18.5174 18.5131C19.7675 17.2595 20 13.4111 20 10Z" />
      </DockSigilFrame>
      <DockSigilInitials>{initials}</DockSigilInitials>
    </DockSigilContainer>
  );
}

const DockGroup = styled('div', {
  position: 'relative',
  minWidth: 0,
  display: 'flex',

  '@mobile': {
    flexDirection: 'row',
    gap: '$4',
  },

  '@notMobile': {
    flexDirection: 'column',
    '&:not(:first-child)': {
      '&::before': {
        ...dockDividerStyles,
      },
    },
  },

  '@tablet': {
    gap: '$8',
    padding: '$8 0',
  },

  '@desktop': {
    gap: '$4',
    padding: '$4 0',
  },
});

const DockSearch = styled('div', {
  gridArea: 'search',
  position: 'relative',
  minWidth: 0,
  display: 'flex',

  '@notMobile': {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'center',
  },
});

const DockAccount = styled('div', {
  gridArea: 'account',
});

const DockOrg = styled('div', {
  gridArea: 'org',
});

const DockAlerts = styled('div', {
  gridArea: 'alerts',
});

const DockTools = styled(DockGroup, {
  gridArea: 'tools',
});

const DockResources = styled(DockGroup, {
  gridArea: 'resources',
});

const DockProducts = styled(DockGroup, {
  gridArea: 'products',

  '@mobile': {
    '&::before': {
      ...dockDividerStyles,
      top: '-$6',
    },
  },

  '@notMobile': {
    height: '100%',
  },

  variants: {
    collapsed: {
      true: {
        '@mobile': {
          ...visuallyHiddenCSS,
        },
      },
      false: {},
    },
  },
});

const DockEnd = styled(DockGroup, {
  gridArea: 'end',
});

const DockCore = styled('div', {
  position: 'relative',
  zIndex: 1,
  display: 'grid',
  background: colors.bgNeutralLight,
  transition: transitions.minimize,

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
  },

  '@mobile': {
    columnGap: '$12',
    gridTemplateColumns: '$40 $40 1fr $40 $40',
    gridTemplateRows: 'min-content min-content',
    gridTemplateAreas:
      '"menu alerts search org account" "products products products products products"',
    padding: '$8 $12',
    strokeBottom: colors.strokeApplicationLight,
    [darkThemeSelector]: {
      strokeBottom: colors.strokeApplicationDark,
    },
  },

  '@notMobile': {
    padding: '$4 $8',
    gridTemplateColumns: '1fr',
    gridTemplateRows: 'min-content min-content 1fr min-content min-content',
    gridTemplateAreas: '"search" "tools" "products" "resources" "end"',
    overflowY: 'auto',
    boxShadow: shadows.layerLight,

    [darkThemeSelector]: {
      boxShadow: shadows.layerDark,
    },
  },

  '@tablet': {
    gap: '$8',
  },

  '@desktop': {
    gap: '$4',
  },

  variants: {
    collapsed: {
      true: {},
      false: {
        '@mobile': {
          rowGap: '$12',
        },
      },
    },
    minimized: {
      true: {
        '@tablet': {
          width: '$60',
          minWidth: '$60',
        },

        '@desktop': {
          width: '$48',
          minWidth: '$48',
        },
      },
      false: {
        '@notMobile': {
          width: '$160',
          minWidth: '$160',
        },
      },
    },
  },
});

const DockSidebar = styled('div', {
  position: 'relative',
  zIndex: 2,
  height: '100%',
  overflow: 'hidden',

  '@mobile': {
    width: '100%',
    backgroundColor: colors.bgApplicationLight,
    strokeTop: colors.strokeApplicationLight,
    [darkThemeSelector]: {
      backgroundColor: colors.bgApplicationDark,
      strokeTop: colors.strokeApplicationDark,
    },
  },

  '@notMobile': {
    boxShadow: shadows.layerLight,
    background: colors.bgApplicationLight,

    [darkThemeSelector]: {
      background: colors.bgApplicationDark,
      boxShadow: shadows.layerDark,
    },
  },

  '@desktop': {
    width: '100%',
  },

  [selectors.safari]: {
    borderRadiusLeft: '$10',
  },

  variants: {
    collapsed: {
      true: {
        '@mobile': {
          display: 'none',
        },
      },
      false: {},
    },
  },
});

const DockContainer = styled(LayoutDock, {
  display: 'flex',
  width: '100%',

  '@mobile': {
    flexDirection: 'column',
    strokeBottom: colors.strokeApplicationLight,
    [darkThemeSelector]: {
      strokeBottom: colors.strokeApplicationDark,
    },
  },

  '@notMobile': {
    flexDirection: 'row',
    height: '100%',
  },
  variants: {
    collapsed: {
      true: {},
      false: {
        '@mobile': {
          gridArea: 'dock / dock / page / page',
        },
      },
    },
  },
});

export interface DockProps {
  account?: React.ReactNode;
  alerts?: React.ReactNode;
  // `location` is typically going to be a `Location` from react-routers `useLocation` hook, but
  // it can be anything that we want to listen to a change on.
  location?: any;
  org?: React.ReactNode;
  products?: React.ReactNode;
  search?: React.ReactNode;
  settings?: React.ReactNode;
  sidebar?: React.ReactNode;
  resources?: React.ReactNode;
  minimized?: boolean;
  setMinimized?: (v: boolean) => void;
}

export function Dock({
  location,
  account,
  alerts,
  org,
  products,
  search,
  settings,
  sidebar,
  resources,
  minimized,
  setMinimized,
}: DockProps) {
  const { breakpoint } = useViewport();
  const isMobile = breakpoint === 'mobile';
  const isTablet = breakpoint === 'tablet';
  const hasTools = account || alerts || org || settings;
  const [collapsed, setCollapsed] = useState(true);

  // Anytime the `location` changes, well collapse the menu.
  useEffect(() => {
    setCollapsed(true);
  }, [location]);

  return (
    <DockContainer collapsed={collapsed}>
      <DockCore collapsed={collapsed} minimized={minimized}>
        {isMobile ? (
          <>
            <DockMenu
              aria-label="Open the menu"
              label="Menu"
              onClick={() => setCollapsed(!collapsed)}
              selected={!collapsed}
            >
              {collapsed ? <DockMenuIconOpen icon="menu" /> : <DockMenuIconClose icon="cross" />}
            </DockMenu>
            {account && <DockAccount>{account}</DockAccount>}
            {org && <DockOrg>{org}</DockOrg>}
            {alerts && <DockAlerts>{alerts}</DockAlerts>}
            {search && <DockSearch>{search}</DockSearch>}
            {(products || settings) && !collapsed && (
              <DockProducts>
                {products}
                {settings}
              </DockProducts>
            )}
          </>
        ) : (
          <DockMinimizedProvider value={minimized}>
            {search && <DockSearch>{search}</DockSearch>}
            {hasTools && (
              <DockTools>
                {account}
                {alerts}
                {org}
                {settings}
              </DockTools>
            )}
            {products && <DockProducts>{products}</DockProducts>}
            {resources && <DockResources>{resources}</DockResources>}
            {setMinimized && (
              <DockEnd>
                <Button
                  variant="secondary"
                  arrangement="hidden-label"
                  icon={!minimized ? 'pane-left' : 'pane-right'}
                  size={isTablet ? 'large' : 'medium'}
                  width="100%"
                  onClick={() => setMinimized(!minimized)}
                >
                  {!minimized ? 'Minimize dock' : 'Maximize dock'}
                </Button>
              </DockEnd>
            )}
          </DockMinimizedProvider>
        )}
      </DockCore>
      {sidebar && <DockSidebar collapsed={collapsed}>{sidebar}</DockSidebar>}
    </DockContainer>
  );
}
