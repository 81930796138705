import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import { Icon } from '../../assets/Icon/Icon';
import { fade, palette } from '../../common/colors';
import { useControlSize } from '../../common/control_size';
import { visuallyHiddenCSS } from '../../common/visually_hidden';
import { colors, darkThemeSelector, styled } from '../../stitches.config';
import { Text } from '../../text/Text';
import { useTableRowSelected } from '../Table/Table';
import { useBadgeEnds } from './BadgeContext';

export type BadgeArrangement = 'hidden-label' | 'leading-icon' | 'leading-label';
export type BadgeEnds = 'card' | 'pill';
export type BadgeSize = 'x-small' | 'small' | 'medium' | 'large';
export type BadgeVariant =
  | 'alternative'
  | 'attention'
  | 'brand'
  | 'negative'
  | 'neutral'
  | 'positive'
  | 'disabled';

export type BadgeProps = {
  /**
   * Set the content's order and visibilty.
   */
  arrangement?: BadgeArrangement;
  /**
   * Displayed as the label of the component. **Required** for accessibility support.
   */
  children: React.ReactNode;
  /**
   * Style choice for the left and right edges of the component.
   */
  ends?: BadgeEnds;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  /**
   * Boolean to show internal-only styles.
   */
  internal?: boolean;
  /**
   * Set a size of the component.
   */
  size?: BadgeSize;
  /**
   * Set the most appropriate variant of the component for your use.
   */
  variant?: BadgeVariant;
  /**
   * Event handler for clicking on a badge.
   */
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  /**
   * Set a width specifically for the badge.
   */
  width?: string;
};

const BadgeIcon = styled(Icon, {
  color: '$$iconColor',
  variants: {
    size: {
      'x-small': {
        width: '$12',
        height: '$12',
      },
      small: {
        width: '$12',
        height: '$12',
      },
      medium: {
        width: '$16',
        height: '$16',
      },
      large: {
        width: '$20',
        height: '$20',
      },
    },
  },
});

const BadgeLabel = styled(Text, {
  maxWidth: '100%',
  color: '$$labelColor',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  variants: {
    isHidden: {
      true: visuallyHiddenCSS,
      false: {},
    },
    size: {
      'x-small': {
        fontSize: '$10',
        lineHeight: '$12',
      },
      small: {
        fontSize: '$12',
        lineHeight: '$16',
      },
      medium: {
        fontSize: '$14',
        lineHeight: '$20',
      },
      large: {
        fontSize: '$14',
        lineHeight: '$20',
      },
    },
  },
});

const BadgeLabelAndIcon = styled('div', {
  maxWidth: '100%',
  hStack: '$4',
  variants: {
    centered: {
      true: {
        margin: '0 auto',
      },
    },
    arrangement: {
      'leading-icon': {
        flexDirection: 'row-reverse',
      },
      'leading-label': {
        flexDirection: 'row',
      },
      'hidden-label': {},
    },
  },
});

export const BadgeContainer = styled('div', {
  display: 'inline-flex',
  width: 'fit-content',
  maxWidth: '100%',

  variants: {
    ends: {
      card: {
        borderRadius: '$8',
      },
      pill: {
        borderRadius: 9999,
      },
    },
    size: {
      'x-small': { lineHeight: '$12', height: '$16', paddingY: '$2' },
      small: { lineHeight: '$16', height: '$20', paddingY: '$2' },
      medium: { lineHeight: '$20', height: '$24', paddingY: '$2' },
      large: { lineHeight: '$20', height: '$28', paddingY: '$4' },
    },
    color: {
      alternative: {
        backgroundColor: fade(palette.tokenBgAlternativeLight, 0.75),
        $$labelColor: colors.blue900,
        $$iconColor: colors.blue800,

        [darkThemeSelector]: {
          backgroundColor: colors.tokenBgAlternativeDark,
          $$labelColor: colors.white,
          $$iconColor: colors.blue50,
        },
      },
      brand: {
        backgroundColor: fade(palette.tokenBgBrandLight, 0.75),
        $$labelColor: colors.brand900,
        $$iconColor: colors.brand800,

        [darkThemeSelector]: {
          backgroundColor: colors.tokenBgBrandDark,
          $$labelColor: colors.white,
          $$iconColor: colors.brand50,
        },
      },
      attention: {
        backgroundColor: fade(palette.tokenBgAttentionLight, 0.75),
        $$labelColor: colors.bodyAttentionLight,
        $$iconColor: colors.amber700,

        [darkThemeSelector]: {
          backgroundColor: colors.tokenBgAttentionDark,
          $$labelColor: colors.white,
          $$iconColor: colors.amber100,
        },
      },
      negative: {
        backgroundColor: fade(palette.tokenBgNegativeLight, 0.75),
        $$labelColor: colors.red900,
        $$iconColor: colors.red800,

        [darkThemeSelector]: {
          backgroundColor: colors.tokenBgNegativeDark,
          $$labelColor: colors.white,
          $$iconColor: colors.red50,
        },
      },
      neutral: {
        backgroundColor: fade(palette.tokenBgNeutralLight, 0.75),
        $$labelColor: colors.gray700,
        $$iconColor: colors.gray600,

        [darkThemeSelector]: {
          backgroundColor: fade(palette.tokenBgNeutralDark, 0.75),
          $$labelColor: colors.white,
          $$iconColor: colors.gray50,
        },
      },
      positive: {
        backgroundColor: fade(palette.tokenBgPositiveLight, 0.75),
        $$labelColor: colors.green900,
        $$iconColor: colors.green800,

        [darkThemeSelector]: {
          backgroundColor: colors.tokenBgPositiveDark,
          $$labelColor: colors.white,
          $$iconColor: colors.green50,
        },
      },
      disabled: {
        backgroundColor: fade(palette.tokenBgNeutralLight, 0.25),
        $$labelColor: colors.gray200,
        $$iconColor: colors.gray100,

        [darkThemeSelector]: {
          backgroundColor: fade(palette.tokenBgNeutralDark, 0.25),
          $$labelColor: colors.white,
          $$iconColor: colors.gray50,
        },
      },
    },
    internal: {
      true: {
        backgroundColor: fade(palette.internalTokenBgLight, 0.75),
        $$labelColor: colors.internalHeadingLight,
        $$iconColor: colors.internalBodyLight,

        [darkThemeSelector]: {
          backgroundColor: fade(palette.internalTokenBgDark, 0.75),
          $$labelColor: colors.internalHeadingDark,
          $$iconColor: colors.internalBodyDark,
        },
      },
    },
    clickable: {
      true: {
        cursor: 'pointer',
        userSelect: 'none',
      },
    },
  },
  compoundVariants: [
    {
      ends: 'card',
      size: 'x-small',
      css: {
        paddingX: '$4',
        borderRadius: '$4',
      },
    },
    {
      ends: 'card',
      size: 'small',
      css: {
        paddingX: '$4',
        borderRadius: '$6',
      },
    },
    {
      ends: 'card',
      size: 'medium',
      css: {
        paddingX: '$8',
        borderRadius: '$8',
      },
    },
    {
      ends: 'card',
      size: 'large',
      css: {
        paddingX: '$10',
        borderRadius: '$12',
      },
    },
    {
      ends: 'pill',
      size: 'x-small',
      css: {
        paddingX: '$4',
      },
    },
    {
      ends: 'pill',
      size: 'small',
      css: {
        paddingX: '$6',
      },
    },
    {
      ends: 'pill',
      size: 'medium',
      css: {
        paddingX: '$8',
      },
    },
    {
      ends: 'pill',
      size: 'large',
      css: {
        paddingX: '$12',
      },
    },
  ],
});

export const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      arrangement = 'leading-label',
      children,
      ends,
      icon,
      internal,
      variant = 'neutral',
      size,
      onClick,
      width,
      ...remaining
    },
    ref,
  ) => {
    const controlSize = useControlSize(size, 'medium') as BadgeSize;
    const badgeEnds = useBadgeEnds(ends, 'card');
    const rowSelected = useTableRowSelected();

    return (
      <BadgeContainer
        internal={internal}
        size={controlSize}
        ends={badgeEnds}
        color={rowSelected && variant === 'neutral' ? 'brand' : variant}
        ref={ref}
        onClick={onClick}
        clickable={onClick !== undefined}
        {...remaining}
        style={{ width }}
      >
        {/*
          TRICKY: This zero-width-space ensures the Badge always has a baseline to
          align with even when the label is visually hidden.
          @see https://www.w3.org/TR/CSS22/visudet.html#propdef-vertical-align
        */}
        <BadgeLabel size={controlSize} aria-hidden>
          {'\u200B'}
        </BadgeLabel>
        <BadgeLabelAndIcon arrangement={arrangement} centered={width !== undefined}>
          <BadgeLabel
            variant="tabular"
            weight="bold"
            whitespace="no-wrap"
            size={controlSize}
            isHidden={arrangement === 'hidden-label'}
          >
            {children}
          </BadgeLabel>
          {icon && <BadgeIcon icon={icon} size={controlSize} />}
        </BadgeLabelAndIcon>
      </BadgeContainer>
    );
  },
);
