import type { SizerProp } from '../../utilities/shared/sizes';
import type { IconName } from '../Icon/Icon';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { sizer } from '../../utilities/shared/sizes';
import { Icon } from '../Icon/Icon';

const FramedIconComponent = styled(Icon, {
  display: 'flex',

  variants: {
    ratio: {
      'x-small': {
        width: '56%',
        height: '56%',
      },
      small: {
        width: '52%',
        height: '52%',
      },
      medium: {
        width: '48%',
        height: '48%',
      },
      large: {
        width: '44%',
        height: '44%',
      },
    },
  },
});

const FramedIconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.bgApplicationLight,
  color: colors.iconNeutralLight,
  boxShadow: shadows.manufacturerLight,

  [darkThemeSelector]: {
    boxShadow: shadows.manufacturerDark,
  },

  variants: {
    radius: {
      'x-small': {
        borderRadius: '$6',
      },
      small: {
        borderRadius: '$8',
      },
      medium: {
        borderRadius: '$10',
      },
      large: {
        borderRadius: '$16',
      },
    },
  },
});

export interface FramedIconProps {
  /**
   * Set which icon to display.
   */
  icon: IconName;
  /**
   * Set a size of the component.
   */
  size?: number;
}

const getFramedIconSizeLabel = (size: number) => {
  switch (true) {
    case size >= 40:
      return 'large';
    case size >= 32:
      return 'medium';
    case size >= 20:
      return 'small';
    default:
      return 'x-small';
  }
};

export function FramedIcon({ icon, size = 28, ...remaining }: FramedIconProps) {
  return (
    <FramedIconContainer
      radius={getFramedIconSizeLabel(size)}
      style={{
        width: sizer(size as SizerProp),
        maxWidth: sizer(size as SizerProp),
        minWidth: sizer(size as SizerProp),
        height: sizer(size as SizerProp),
        maxHeight: sizer(size as SizerProp),
        minHeight: sizer(size as SizerProp),
      }}
      {...remaining}
    >
      <FramedIconComponent ratio={getFramedIconSizeLabel(size)} icon={icon} size={size} />
    </FramedIconContainer>
  );
}
