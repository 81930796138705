import { ManufacturerIconSVG } from 'atto-svgs';

import type { SizerProp } from '../../utilities/shared/sizes';
import { colors, darkThemeSelector, shadows, styled } from '../../stitches.config';
import { sizer, space } from '../../utilities/shared/sizes';

export const ManufacturerIcons = {
  amazon: ManufacturerIconSVG.Amazon,
  apple: ManufacturerIconSVG.Apple,
  asus: ManufacturerIconSVG.Asus,
  dell: ManufacturerIconSVG.Dell,
  ecobee: ManufacturerIconSVG.Ecobee,
  google: ManufacturerIconSVG.Google,
  hp: ManufacturerIconSVG.HP,
  ibm: ManufacturerIconSVG.IBM,
  intel: ManufacturerIconSVG.Intel,
  lenovo: ManufacturerIconSVG.Lenovo,
  lg: ManufacturerIconSVG.LG,
  logitech: ManufacturerIconSVG.Logitech,
  microsoft: ManufacturerIconSVG.Microsoft,
  nest: ManufacturerIconSVG.Nest,
  philips: ManufacturerIconSVG.Philips,
  samsung: ManufacturerIconSVG.Samsung,
  shure: ManufacturerIconSVG.Shure,
  sonos: ManufacturerIconSVG.Sonos,
  unknown: ManufacturerIconSVG.Unknown,
};

export type ManufacturerIconName = keyof typeof ManufacturerIcons;

const ManufacturerIconBase = styled('svg', {
  display: 'block',
  variants: {
    size: {
      small: {
        width: '100%',
        height: '100%',
      },
      medium: {
        width: '75%',
        height: '75%',
      },
      large: {
        width: '60%',
        height: '60%',
      },
    },
  },
});

const getSize = (size: number) => {
  switch (true) {
    case size >= 40:
      return 'large';
    case size >= 24:
      return 'medium';
    default:
      return 'small';
  }
};
export interface ManufacturerIconProps {
  /**
   * Set which manufacturer to display.
   */
  icon: ManufacturerIconName;
  /**
   * Set a size of the component.
   */
  size?: number;
}

function ManufacturerIconIcon({ icon, size = space(28), ...remaining }: ManufacturerIconProps) {
  const NamedIcon = ManufacturerIcons[icon];

  if (!NamedIcon) {
    // eslint-disable-next-line no-console
    console.error(`Missing icon named ${icon}`);
    return null;
  }

  return <ManufacturerIconBase size={getSize(size)} as={NamedIcon} {...remaining} />;
}

const ManufacturerIconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: colors.bgApplicationLight,
  boxShadow: shadows.manufacturerLight,

  [darkThemeSelector]: {
    boxShadow: shadows.manufacturerDark,
  },

  variants: {
    size: {
      large: {
        borderRadius: '$16',
      },
      medium: {
        borderRadius: '$10',
      },
      small: {
        borderRadius: '$4',
        boxShadow: 'none',

        [darkThemeSelector]: {
          boxShadow: 'none',
        },
      },
    },
  },
});

export function ManufacturerIcon({ icon, size = 28, ...remaining }: ManufacturerIconProps) {
  return (
    <ManufacturerIconContainer
      size={getSize(size)}
      style={{
        width: sizer(size as SizerProp),
        maxWidth: sizer(size as SizerProp),
        minWidth: sizer(size as SizerProp),
        height: sizer(size as SizerProp),
        maxHeight: sizer(size as SizerProp),
        minHeight: sizer(size as SizerProp),
      }}
      {...remaining}
    >
      <ManufacturerIconIcon icon={icon} size={size} />
    </ManufacturerIconContainer>
  );
}
