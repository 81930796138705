import type { HardwareIconPropHardware } from '../HardwareIcon/HardwareIcon';
import type { ManufacturerIconName } from '../ManufacturerIcon/ManufacturerIcon';
import type { ProviderIconName } from '../ProviderIcon/ProviderIcon';
import { space } from '../../utilities/shared/sizes';
import { FramedIcon } from '../FramedIcon/FramedIcon';
import { HardwareIcon } from '../HardwareIcon/HardwareIcon';
import { type IconName, Icon } from '../Icon/Icon';
import { ManufacturerIcon } from '../ManufacturerIcon/ManufacturerIcon';
import { type ProductIconName, ProductIcon } from '../ProductIcon/ProductIcon';
import { ProviderIcon } from '../ProviderIcon/ProviderIcon';

export type DynamicIconPropIcon =
  | HardwareIconPropHardware
  | IconName
  | ManufacturerIconName
  | ProductIconName
  | ProviderIconName;

export type DynamicIconProps = {
  icon: DynamicIconPropIcon;
  size?: number;
};

function DynamicIconElement({
  icon,
  size,
  ...remaining
}: {
  icon: DynamicIconPropIcon;
  size: number;
}) {
  switch (icon) {
    case 'amazon':
    case 'apple':
    case 'asus':
    case 'dell':
    case 'ecobee':
    case 'google':
    case 'hp':
    case 'ibm':
    case 'intel':
    case 'lenovo':
    case 'lg':
    case 'logitech':
    case 'microsoft':
    case 'nest':
    case 'philips':
    case 'samsung':
    case 'shure':
    case 'sonos':
    case 'unknown':
      if (size < 20) {
        return <Icon icon="microchip" size={size} {...remaining} />;
      }
      return <ManufacturerIcon icon={icon} size={size} {...remaining} />;
    case 'allstream':
    case 'att':
    case 'cogent':
    case 'comcastBusiness':
    case 'cox':
    case 'cradlePoint':
    case 'crownCastle':
    case 'cruzio':
    case 'earthLink':
    case 'fastmetrics':
    case 'for2Fi':
    case 'frontier':
    case 'gtt':
    case 'hughesNet':
    case 'lumen':
    case 'monkeybrains':
    case 'pilot':
    case 'rcn':
    case 'sonic':
    case 'spectrum':
    case 'tMobile':
    case 'verizon':
    case 'viasat':
    case 'wave':
    case 'webpass':
    case 'wiLine':
    case 'windstream':
    case 'wow':
    case 'xfinity':
    case 'zayo':
      if (size < 20) {
        return <Icon icon="globe" size={size} {...remaining} />;
      }
      return <ProviderIcon provider={icon} size={size} {...remaining} />;
    case 'access-point':
    case 'pdu':
    case 'power-distribution-unit':
    case 'switch':
    case 'security-appliance':
      if (size < 20) {
        return <Icon icon={icon} size={size} {...remaining} />;
      }
      return <HardwareIcon hardware={icon} size={size} {...remaining} />;
    case 'access':
    case 'auto-vpn':
    case 'command':
    case 'connect':
    case 'hub':
    case 'network':
    case 'operator':
    case 'spend':
    case 'tunnel':
      return <ProductIcon product={icon} size={size} {...remaining} />;
    default:
      if (size < 20) {
        return <Icon icon={icon} size={size} {...remaining} />;
      }
      return <FramedIcon icon={icon} size={size} {...remaining} />;
  }
}

export function DynamicIcon({ icon, size = space(16), ...remaining }: DynamicIconProps) {
  return <DynamicIconElement icon={icon} size={size} {...remaining} />;
}
