import { safeParseAddress4 } from '@meterup/common';
import { freeze, immerable } from 'immer';
import { v4 } from 'uuid';

import type { ct } from '../schema/config';
import { getStableID } from './getStableID';

export enum MeterV1NetworkNATPortForwardProtocol {
  TCP = 'tcp',
  UDP = 'udp',
}

const protocolFromJSON = (
  json: ct.MeterV1NetworkNATPortForward['protocol'],
): MeterV1NetworkNATPortForwardProtocol => {
  switch (json) {
    case 'tcp':
      return MeterV1NetworkNATPortForwardProtocol.TCP;
    case 'udp':
      return MeterV1NetworkNATPortForwardProtocol.UDP;
    default:
      return MeterV1NetworkNATPortForwardProtocol.TCP;
  }
};

export class MeterV1NetworkNATPortForward {
  [immerable] = true;

  private constructor(
    public stableId: string,
    public externalPort: number,
    public internalPort: number,
    public internalIPAddress: string,
    public protocol: MeterV1NetworkNATPortForwardProtocol,
    public description: string,
    public json: ct.MeterV1NetworkNATPortForward,
  ) {}

  static fromJSON(json: ct.MeterV1NetworkNATPortForward): MeterV1NetworkNATPortForward {
    const stableId =
      json.id ??
      getStableID(
        [
          json.external?.port ?? 0,
          json.internal?.port ?? 0,
          safeParseAddress4(json.internal?.['ip-address'])?.bigInteger() ?? '',
          json.protocol,
        ].join(''),
      );

    const value = new MeterV1NetworkNATPortForward(
      stableId,
      json.external?.port ?? 0,
      json.internal?.port ?? 0,
      json.internal?.['ip-address'] ?? '',
      protocolFromJSON(json.protocol),
      json.description ?? '',
      json,
    );

    return freeze(value, true);
  }

  static fromProps(
    id: string | null,
    {
      externalPort,
      internalPort,
      internalIPAddress,
      protocol,
      description,
    }: {
      externalPort: number;
      internalPort: number;
      internalIPAddress: string;
      protocol: MeterV1NetworkNATPortForwardProtocol;
      description: string;
    },
  ): MeterV1NetworkNATPortForward {
    const stableId = id ?? v4();
    const value = new MeterV1NetworkNATPortForward(
      stableId,
      externalPort,
      internalPort,
      internalIPAddress,
      protocol,
      description,
      {},
    );
    return freeze(value, true);
  }

  toJSON(): ct.MeterV1NetworkNATPortForward {
    return {
      ...this.json,
      external: {
        ...this.json.external,
        port: Number(this.externalPort),
      },
      internal: {
        ...this.json.internal,
        port: Number(this.internalPort),
        'ip-address': this.internalIPAddress,
      },
      protocol: this.protocol,
      description: this.description.length > 0 ? this.description : undefined,
      id: this.stableId,
    };
  }
}
