/* eslint-disable no-param-reassign,max-classes-per-file */
import type * as ct from '../generated/types';
import type { MeterV1NetworkVPPConf } from '../models/MeterV1NetworkVPPConfig';

export const METER_V1_NETWORK_VLAN_PREFIX = 'meter.v1.network.vlan.';
export const METER_V2_WIRELESS_TAGS_PREFIX = 'meter.v2.wireless.tags.';
export const METER_V2_WIRELESS_ACCESS_POINTS_PREFIX = 'meter.v2.wireless.access-points.';
export const METER_V1_NETWORK_WAN_PREFIX = 'meter.v1.network.wan.';
export const METER_V1_NETWORK_VPN_PREFIX = 'meter.v1.network.vpn.';
export const METER_V1_NETWORK_VPN_IPSEC_PREFIX = 'meter.v1.network.vpn.ipsec.';

export interface ControllerConfigObject {
  'meter.v1.meta'?: ct.MeterV1Meta;
  'meter.v1.network.nat'?: ct.MeterV1NetworkNAT;
  'meter.v1.network.vpp-conf'?: MeterV1NetworkVPPConf;
  [k: `${typeof METER_V1_NETWORK_VLAN_PREFIX}${string}`]: ct.MeterV1NetworkVLAN | undefined;
  [k: `${typeof METER_V1_NETWORK_WAN_PREFIX}${string}`]: ct.MeterV1NetworkWAN | undefined;
  [k: `${typeof METER_V2_WIRELESS_ACCESS_POINTS_PREFIX}${string}`]:
    | ct.MeterV2WirelessAccessPoint
    | undefined;
  [k: `${typeof METER_V2_WIRELESS_TAGS_PREFIX}${string}`]: ct.MeterV2WirelessTag | undefined;

  // HACK: We don't look at what's in here, just want to know if it exists
  [k: `${typeof METER_V1_NETWORK_VPN_PREFIX}${string}`]: unknown | undefined;
  [k: `${typeof METER_V1_NETWORK_VPN_IPSEC_PREFIX}${string}`]: unknown | undefined;
}

export { ct };
