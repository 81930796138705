import type * as Polymorphic from '@radix-ui/react-polymorphic';
import React from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type { ManufacturerIconName } from '../../assets/ManufacturerIcon/ManufacturerIcon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { ManufacturerIcon } from '../../assets/ManufacturerIcon/ManufacturerIcon';
import { fade, palette } from '../../common/colors';
import { selectors, transitions } from '../../controls/shared/styles';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Text } from '../../text/Text';

export type DeviceTargetPropType = ManufacturerIconName | IconName;
type DeviceTargetPropSize = 'large' | 'medium' | 'small';

export type DeviceTargetProps = {
  'aria-label': string;
  children: React.ReactNode;
  onClick?: (event: any) => void;
  size?: DeviceTargetPropSize;
  type?: DeviceTargetPropType;
  wrap?: boolean;
  isActive?: boolean;
};

const DeviceTargetIconManufacturer = styled(ManufacturerIcon);

const DeviceTargetIconResource = styled(Icon, {
  color: colors.linkIconInitialLight,

  [darkThemeSelector]: {
    color: colors.linkIconInitialDark,
  },
});

const DeviceTargetIconContainer = styled('div', {
  position: 'relative',
  zIndex: 2,
  display: 'flex',

  variants: {
    size: {
      large: {
        '@notDesktop': {
          paddingTop: '$6',
        },
        '@desktop': {
          paddingTop: '$4',
        },
      },
      medium: {
        '@notDesktop': {
          paddingTop: '$4',
        },
        '@desktop': {
          paddingTop: '$2',
        },
      },
      small: {
        '@notDesktop': {
          paddingTop: '$2',
        },
      },
    },
  },
});

const DeviceTargetLabel = styled(Text, {
  position: 'relative',
  zIndex: '2',
  display: 'flex',
  fontWeight: fontWeights.medium,

  variants: {
    size: {
      large: {
        hStack: '$6',
        '@notDesktop': {
          fontSize: '$24',
          lineHeight: '$32',
        },
        '@desktop': {
          fontSize: '$20',
          lineHeight: '$28',
        },
      },
      medium: {
        hStack: '$4',
        '@notDesktop': {
          fontSize: '$16',
          lineHeight: '$24',
        },
        '@desktop': {
          fontSize: '$14',
          lineHeight: '$20',
        },
      },
      small: {
        hStack: '$4',
        '@notDesktop': {
          fontSize: '$14',
          lineHeight: '$20',
        },
        '@desktop': {
          fontSize: '$12',
          lineHeight: '$16',
        },
      },
    },
    wrap: {
      true: {
        wordBreak: 'break-word',
        whiteSpace: 'pre-line',
      },
      false: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
    clickable: {
      true: {
        color: colors.linkInitialLight,

        [darkThemeSelector]: {
          color: colors.linkInitialDark,
        },
      },
      false: {},
    },
  },
});

const DeviceTargetBoundary = styled('div', {
  position: 'absolute',
  zIndex: 1,
  transition: transitions.control,
});

const DeviceTargetContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '100%',
  transition: transitions.control,

  variants: {
    size: {
      large: {
        gap: '$6',
        display: 'inline-flex',

        [`${DeviceTargetBoundary}`]: {
          borderRadius: '10px',
          top: '-4px',
          right: '-12px',
          bottom: '-4px',
          left: '-12px',
        },
      },
      medium: {
        gap: '$4',
        display: 'inline-flex',

        [`${DeviceTargetBoundary}`]: {
          borderRadius: '$8',
          top: '-4px',
          right: '-6px',
          bottom: '-4px',
          left: '-6px',
        },
      },
      small: {
        gap: '$4',
        display: 'inline-flex',

        [`${DeviceTargetBoundary}`]: {
          borderRadius: '$6',
          top: '-2px',
          right: '-4px',
          bottom: '-2px',
          left: '-4px',
        },
      },
    },
    isActive: {
      true: {
        [`${DeviceTargetBoundary}`]: {
          backgroundColor: colors.bgBrandLight,
          strokeAll: colors.strokeBrandLight,
          [darkThemeSelector]: {
            backgroundColor: colors.bgBrandDark,
            strokeAll: colors.strokeBrandDark,
          },
        },
      },
    },
    clickable: {
      true: {
        cursor: 'pointer',

        [selectors.hover]: {
          [`${DeviceTargetBoundary}`]: {
            backgroundColor: fade(palette.tokenBgNeutralLight, 0.4),
            strokeAll: fade(palette.tokenStrokeNeutralLight, 0.5),

            [darkThemeSelector]: {
              backgroundColor: fade(palette.tokenBgNeutralDark, 0.3),
              strokeAll: fade(palette.tokenStrokeNeutralDark, 0.4),
            },
          },

          [`${DeviceTargetIconResource}`]: {
            color: colors.linkIconHoverLight,

            [darkThemeSelector]: {
              color: colors.linkIconHoverDark,
            },
          },

          [`${DeviceTargetLabel}`]: {
            color: colors.linkHoverLight,

            [darkThemeSelector]: {
              color: colors.linkHoverDark,
            },
          },
        },

        [selectors.focus]: {
          outline: 'none',

          [`${DeviceTargetBoundary}`]: {
            backgroundColor: colors.bgBrandLight,
            boxShadow: shadows.focusRingLight,

            [darkThemeSelector]: {
              backgroundColor: colors.bgBrandDark,
              boxShadow: shadows.focusRingDark,
            },
          },
        },
      },
      false: {},
    },
  },
});

const getDeviceTargetIconSize = (size: DeviceTargetPropSize) => {
  switch (size) {
    case 'large':
      return 20;
    default:
      return 16;
  }
};

function DeviceTargetIcon({
  size,
  type,
  clickable = false,
}: {
  size: DeviceTargetPropSize;
  type: DeviceTargetPropType;
  clickable?: boolean;
}) {
  switch (type) {
    case 'amazon':
    case 'apple':
    case 'asus':
    case 'dell':
    case 'ecobee':
    case 'google':
    case 'hp':
    case 'ibm':
    case 'intel':
    case 'lenovo':
    case 'lg':
    case 'logitech':
    case 'microsoft':
    case 'nest':
    case 'philips':
    case 'samsung':
    case 'shure':
    case 'sonos':
    case 'unknown':
      return <DeviceTargetIconManufacturer icon={type} size={getDeviceTargetIconSize(size)} />;
    default:
      return clickable ? (
        <DeviceTargetIconResource icon={type} size={getDeviceTargetIconSize(size)} />
      ) : (
        <Icon icon={type} size={getDeviceTargetIconSize(size)} />
      );
  }
}

export const DeviceTarget = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      as = 'a' as Tag,
      children,
      type,
      onClick,
      size = 'medium',
      wrap = true,
      isActive = false,
      ...remaining
    }: PolymorphicComponentProps<Tag, DeviceTargetProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => (
    <DeviceTargetContainer
      as={as}
      ref={forwardedRef}
      tabIndex={0}
      role={onClick && 'button'}
      onClick={onClick}
      size={size}
      isActive={isActive}
      clickable
      {...remaining}
    >
      <DeviceTargetIconContainer size={size}>
        {type && <DeviceTargetIcon size={size} type={type} clickable />}
      </DeviceTargetIconContainer>
      <DeviceTargetLabel size={size} wrap={wrap} clickable>
        {children}
      </DeviceTargetLabel>
      <DeviceTargetBoundary />
    </DeviceTargetContainer>
  ),
) as Polymorphic.ForwardRefComponent<'a', DeviceTargetProps>;

/* Same as above but without a link */
export const DevicePlaceholder = React.forwardRef(
  <Tag extends React.ElementType>(
    {
      as = 'span' as Tag,
      children,
      type,
      onClick,
      size = 'medium',
      wrap = true,
      isActive = false,
      ...remaining
    }: PolymorphicComponentProps<Tag, DeviceTargetProps>,
    forwardedRef: PolymorphicRef<Tag>,
  ) => (
    <DeviceTargetContainer
      as={as}
      ref={forwardedRef}
      tabIndex={0}
      role={onClick && 'button'}
      onClick={onClick}
      size={size}
      isActive={isActive}
      {...remaining}
    >
      <DeviceTargetIconContainer size={size}>
        {type && <DeviceTargetIcon size={size} type={type} />}
      </DeviceTargetIconContainer>
      <DeviceTargetLabel size={size} wrap={wrap}>
        {children}
      </DeviceTargetLabel>
      <DeviceTargetBoundary />
    </DeviceTargetContainer>
  ),
) as Polymorphic.ForwardRefComponent<'span', DeviceTargetProps>;
