import React from 'react';

import { colors, darkThemeSelector, styled } from '../../stitches.config';

export const FieldContainerBase = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  background: colors.bgNeutralLight,
  strokeAll: colors.strokeNeutralLight,
  borderRadius: '$10',

  [darkThemeSelector]: {
    background: colors.bgNeutralDark,
    strokeAll: colors.strokeNeutralDark,
  },

  [`& > div:first-child`]: {
    borderRadiusTop: '$8',
  },

  [`& > div:last-child`]: {
    borderRadiusBottom: '$8',
  },
});

const FieldContainerContext = React.createContext(false);

export function useFieldContainerContext() {
  return React.useContext(FieldContainerContext);
}

export const FieldContainer = React.forwardRef<
  HTMLDivElement,
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
>((props, ref) => (
  <FieldContainerContext.Provider value>
    <FieldContainerBase {...props} ref={ref} />
  </FieldContainerContext.Provider>
));

export function MaybeFieldContainer({ children }: { children: React.ReactNode }) {
  const isInsideFieldContainer = useFieldContainerContext();
  const WrapperEl = isInsideFieldContainer ? React.Fragment : FieldContainer;
  return <WrapperEl>{children}</WrapperEl>;
}
