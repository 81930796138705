import type {
  DropzoneUploaderProps,
  FileInfo,
  FileUploaderError,
  ReactDropzoneProps,
} from '@meterup/atto/src/components/DropzoneUploader/DropzoneUploader';
import { DropzoneUploader } from '@meterup/atto';
import { graphqlClient, useGraphQL } from '@meterup/graphql';
import { uniqueId } from 'lodash-es';
import React, { useCallback, useEffect, useState } from 'react';

import type { AllowedFolder } from '../gql/graphql';
import { graphql } from '../gql/index';

const createPresignedUploadUrlMutation = graphql(`
  mutation CreatePreSignedUploadUrlMutation($folder: AllowedFolder!, $fileExtension: String!) {
    createPreSignedUploadUrl(input: { folder: $folder, fileExtension: $fileExtension }) {
      key
      presignedUrl
    }
  }
`);

export const presignedDownloadUrlQuery = graphql(`
  query PresignedDownloadUrlQuery($s3Key: String!) {
    downloadUrl(input: { s3Key: $s3Key }) {
      presignedUrl
    }
  }
`);

/**
 * Use this hook to get a presigned URL for a file in S3.
 *
 * URLs expire after 15 minutes so they should be consumed **immediately!**
 */
export function usePresignedFileUrl({ s3Key }: { s3Key: string }) {
  const v = useGraphQL(presignedDownloadUrlQuery, { s3Key });
  return {
    url: v.data?.downloadUrl.presignedUrl,
    status: v.status,
  };
}

/**
 * A simple wrapper around the img tag that fetches a presigned URL for an image in S3.
 */
export function PresignedImage(
  props: { s3Key: string } & React.ImgHTMLAttributes<HTMLImageElement>,
) {
  const { s3Key, ...rest } = props;
  const presignedUrl = usePresignedFileUrl({ s3Key });
  if (!presignedUrl) return null;
  return <img src={presignedUrl.url} {...rest} />;
}

type UploadedFileDetails = {
  s3Key: string;
};

export interface UnifiedFileUploaderProps {
  /**
   * The folder in S3 which the file will be uploaded to. IMPORTANT! The folder must be allow-listed in the backend.
   */
  folder: AllowedFolder;
  /**
   * The types of files that the user is allowed to upload.
   */
  allowedTypes?: DropzoneUploaderProps['allowedTypes'];
  /**
   * Manually sets the accepted file mime types/extensions.
   * If used in conjunction with `allowedTypes`, the types/extensions specified in this prop will take precedence.
   */
  accept?: ReactDropzoneProps['accept'];
  /**
   * The maximum number of files that the user is allowed to upload.
   */
  maxFiles?: number;
  /**
   * The maximum size of a file that the user is allowed to upload. If unspecified, there is no limit.
   */
  maxFileSizeBytes?: number;
  /**
   * Called when a file is successfully uploaded.
   */
  onFileUploaded?: (details: UploadedFileDetails) => void;
  /**
   * Called when a file that has previously been uploaded is removed by the user.
   */
  onFileRemoved?: (details: UploadedFileDetails) => void;

  /**
   * Renders a compact version of the component for display in narrow areas
   */
  compact?: boolean;
}

async function initFileUploader(
  file: File,
  folder: AllowedFolder,
  { onProgress }: { onProgress: (percentage: number) => void },
) {
  const fileExtension = file.name.split('.').findLast(() => true)!;

  const { createPreSignedUploadUrl: presignedUrlDetails } = await graphqlClient.request(
    createPresignedUploadUrlMutation,
    {
      fileExtension,
      folder,
    },
  );

  const { presignedUrl } = presignedUrlDetails;

  const xhr = new XMLHttpRequest();
  const uploadPromise = new Promise<void>((resolve, reject) => {
    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const approximatePercentage = Math.floor((event.loaded / event.total) * 100);
        onProgress(approximatePercentage);
      }
    });
    xhr.addEventListener('loadend', () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        resolve();
      } else {
        reject(new Error(`File upload failed... XHR status: ${xhr.status}`));
      }
    });
    xhr.open('PUT', presignedUrl, true);
    xhr.setRequestHeader('Content-Type', file.type);
    xhr.send(file);
  });

  return {
    s3Key: presignedUrlDetails.key,
    abortUpload: () => {
      if (xhr.readyState !== xhr.DONE) {
        xhr.abort();
      }
    },
    uploadPromise,
  };
}

type FileUploadInfo = {
  didNotifyUploaded: boolean;
  error: FileUploaderError | null;
  uploadedS3Key: string | null;
  uploadPercentage: number;
  abortUploadFn?: () => void;
};

export default function UnifiedFileUploader(props: UnifiedFileUploaderProps) {
  const [files, setFiles] = useState<(FileInfo & FileUploadInfo)[]>([]);

  const { folder, onFileUploaded } = props;

  const startUpload = useCallback(
    async (file: FileInfo & FileUploadInfo) => {
      function updateFileUploadDetails(id: string, patch: Partial<FileUploadInfo>) {
        setFiles((prevFiles) =>
          prevFiles.map((prevFile) => {
            if (prevFile.id === id) {
              return {
                ...prevFile,
                ...patch,
              };
            }
            return prevFile;
          }),
        );
      }

      if (props.maxFileSizeBytes && file.fileObject.size > props.maxFileSizeBytes) {
        updateFileUploadDetails(file.id, {
          error: {
            message: 'File size exceeds the maximum allowed size.',
            isValidationError: true,
          },
        });
        return;
      }

      // reset
      updateFileUploadDetails(file.id, {
        uploadPercentage: 0,
        uploadedS3Key: null,
        error: null,
        abortUploadFn: undefined,
        didNotifyUploaded: false,
      });

      const uploader = await initFileUploader(file.fileObject, folder, {
        onProgress: (percentage) =>
          updateFileUploadDetails(file.id, { uploadPercentage: percentage }),
      });

      updateFileUploadDetails(file.id, {
        abortUploadFn: uploader.abortUpload,
      });

      uploader.uploadPromise
        .then(() =>
          updateFileUploadDetails(file.id, {
            uploadedS3Key: uploader.s3Key,
            uploadPercentage: 100,
          }),
        )
        .catch((error) =>
          updateFileUploadDetails(file.id, {
            error: error as Error,
            uploadPercentage: 0,
          }),
        );
    },
    [folder, props.maxFileSizeBytes],
  );

  // This is wrapped is useCallback as recommended by the docs for Dropzone
  const onDrop = useCallback<DropzoneUploaderProps['onDrop']>(
    (droppedFiles) => {
      const droppedFilesWithUploadPercentage = droppedFiles.map((file) => ({
        fileObject: file,
        uploadPercentage: 0,
        id: uniqueId(),
        didNotifyUploaded: false,
        uploadedS3Key: null,
        error: null,
      }));

      setFiles((prevFiles) => [...prevFiles, ...droppedFilesWithUploadPercentage]);

      droppedFilesWithUploadPercentage.forEach(startUpload);
    },
    [startUpload],
  );

  useEffect(() => {
    files.forEach((file) => {
      if (!file.didNotifyUploaded && file.uploadPercentage === 100 && file.uploadedS3Key) {
        onFileUploaded?.({ s3Key: file.uploadedS3Key });
        // marking didNotifyUploaded prevents the effect logic from firing multiple times
        // even _if_ the user of the component accidentally passes us an unstable onFileUploaded fn
        setFiles((prevFiles) =>
          prevFiles.map((prevFile) => {
            if (prevFile.id === file.id) {
              return { ...prevFile, didNotifyUploaded: true };
            }
            return prevFile;
          }),
        );
      }
    });
  }, [files, folder, onFileUploaded]);

  return (
    <DropzoneUploader
      maxFiles={props.maxFiles}
      accept={props.accept}
      allowedTypes={props.allowedTypes}
      compact={props.compact}
      onDrop={onDrop}
      onFileRemoved={(id) => {
        setFiles((prevFiles) =>
          prevFiles.filter((file) => {
            if (file.id === id) {
              if (file.abortUploadFn) {
                file.abortUploadFn();
              }
              if (props.onFileRemoved && file.didNotifyUploaded && file.uploadedS3Key) {
                props.onFileRemoved({ s3Key: file.uploadedS3Key });
              }
              return false;
            }
            return true;
          }),
        );
      }}
      files={files}
      onRetryUpload={(id) => {
        const file = files.find((f) => f.id === id);
        if (!file) return;
        startUpload(file);
      }}
    />
  );
}
