import type { ValueOf } from 'ts-pattern/dist/types/helpers';
import { freeze, immerable } from 'immer';

import type { ControllerConfigObject, ct } from '../schema/config';
import type { MeterV1NetworkVPPConf } from './MeterV1NetworkVPPConfig';
import {
  METER_V1_NETWORK_VLAN_PREFIX,
  METER_V1_NETWORK_VPN_IPSEC_PREFIX,
  METER_V1_NETWORK_VPN_PREFIX,
  METER_V2_WIRELESS_ACCESS_POINTS_PREFIX,
  METER_V2_WIRELESS_TAGS_PREFIX,
} from '../schema/config';
import { MeterV1NetworkNAT } from './MeterV1NetworkNAT';
import { MeterV1NetworkVLAN } from './MeterV1NetworkVLAN';
import { MeterV2WirelessAccessPoint } from './MeterV2WirelessAccessPoint';
import { MeterV2WirelessServiceSet } from './MeterV2WirelessServiceSet';
import { MeterV2WirelessTag } from './MeterV2WirelessTag';

function getKeyValuePairsFromPrefix<T extends ValueOf<ControllerConfigObject>>(
  prefix: string,
  json: ControllerConfigObject,
) {
  return Object.entries(json)
    .filter(([key]) => key.startsWith(prefix))
    .map(([key, value]) => ({
      fullKey: key,
      shortKey: key.substring(prefix.length),
      value: value as T,
    }));
}

export class MeterControllerConfig {
  [immerable] = true;

  private constructor(
    public tags: MeterV2WirelessTag[],
    public serviceSets: MeterV2WirelessServiceSet[],
    public vlans: MeterV1NetworkVLAN[],
    public accessPoints: MeterV2WirelessAccessPoint[],
    public nat: MeterV1NetworkNAT,
    public json: ControllerConfigObject,
  ) {}

  static fromJSON(json: ControllerConfigObject): MeterControllerConfig {
    const tagsJSON = getKeyValuePairsFromPrefix<ct.MeterV2WirelessTag>(
      METER_V2_WIRELESS_TAGS_PREFIX,
      json,
    );

    const tags = tagsJSON.map(({ shortKey, value }) =>
      MeterV2WirelessTag.fromJSON(shortKey, value),
    );

    const vlans = getKeyValuePairsFromPrefix<ct.MeterV1NetworkVLAN>(
      METER_V1_NETWORK_VLAN_PREFIX,
      json,
    ).map(({ shortKey, value }) => MeterV1NetworkVLAN.fromJSON(shortKey, value));

    const accessPoints = getKeyValuePairsFromPrefix<ct.MeterV2WirelessAccessPoint>(
      METER_V2_WIRELESS_ACCESS_POINTS_PREFIX,
      json,
    ).map(({ shortKey, value }) => MeterV2WirelessAccessPoint.fromJSON(shortKey, value));

    const serviceSets = tagsJSON
      .map(({ shortKey: tagName, value }) =>
        Object.entries(value['service-sets'] ?? {}).map(([ssid, ssidJSON]) =>
          MeterV2WirelessServiceSet.fromJSON(tagName, ssid, ssidJSON),
        ),
      )
      .flat();

    const nat = MeterV1NetworkNAT.fromJSON(json['meter.v1.network.nat'] ?? {});

    return freeze(
      new MeterControllerConfig(tags, serviceSets, vlans, accessPoints, nat, json),
      true,
    );
  }

  getAPsBroadcastingServiceSet(
    serviceSet: MeterV2WirelessServiceSet,
  ): MeterV2WirelessAccessPoint[] {
    const tag = this.tags.find((t) => t.name === serviceSet.tagName);

    if (!tag) {
      return [];
    }

    return this.getAccessPointsWithTag(tag);
  }

  getCustomerFacingVLANs(): MeterV1NetworkVLAN[] {
    return this.vlans.filter(
      (vlan) => vlan.name !== 'mgmt' && vlan.name !== 'apmgmt' && vlan.name !== 'novlan',
    );
  }

  doesAccessPointBroadcastServiceSet(
    accessPoint: MeterV2WirelessAccessPoint,
    serviceSet: MeterV2WirelessServiceSet,
  ): boolean {
    const tag = this.tags.find((t) => t.name === serviceSet.tagName);

    if (!tag) {
      return false;
    }

    return this.doesAccessPointHaveTag(accessPoint, tag);
  }

  // eslint-disable-next-line class-methods-use-this
  private doesAccessPointHaveTag(
    accessPoint: MeterV2WirelessAccessPoint,
    tag: MeterV2WirelessTag,
  ): boolean {
    return accessPoint.json.includes(tag.name);
  }

  getServiceSetByStableId(stableId: string): MeterV2WirelessServiceSet | null {
    return this.serviceSets.find((serviceSet) => serviceSet.stableId === stableId) ?? null;
  }

  getServiceSetsByVLAN(vlan: string): MeterV2WirelessServiceSet[] {
    return this.serviceSets.filter((serviceSet) => serviceSet.vlan === vlan);
  }

  getVLANByName(name: string): MeterV1NetworkVLAN | null {
    return this.vlans.find((vlan) => vlan.name === name) ?? null;
  }

  upsertServiceSet(serviceSet: MeterV2WirelessServiceSet) {
    const index = this.serviceSets.findIndex((s) => s.stableId === serviceSet.stableId);

    if (index === -1) {
      this.serviceSets.push(serviceSet);
    }

    this.serviceSets[index] = serviceSet;
  }

  removeServiceSetById(id: string) {
    const index = this.serviceSets.findIndex((s) => s.stableId === id);

    if (index !== -1) {
      this.serviceSets.splice(index, 1);
    }
  }

  hasVPNEnabled(): boolean {
    return Object.entries(this.json).some(([key]) => key.startsWith(METER_V1_NETWORK_VPN_PREFIX));
  }

  hasIPSecVPNEnabled(): boolean {
    return Object.entries(this.json).some(([key]) =>
      key.startsWith(METER_V1_NETWORK_VPN_IPSEC_PREFIX),
    );
  }

  vppConf(): MeterV1NetworkVPPConf | undefined {
    return this.json['meter.v1.network.vpp-conf'];
  }

  hasWANACL(): boolean {
    return Object.entries(this.json).some(
      ([key, value]) => key.startsWith(METER_V1_NETWORK_VLAN_PREFIX) && !!value['acl-rules'],
    );
  }

  private getAccessPointsWithTag(tag: MeterV2WirelessTag) {
    return this.accessPoints.filter((ap) => ap.json.includes(tag.name));
  }

  private getServiceSetsForTag(tagName: string) {
    return this.serviceSets.filter((serviceSet) => serviceSet.tagName === tagName);
  }

  toJSON(): ControllerConfigObject {
    return {
      ...this.json,
      'meter.v1.network.nat': this.nat.toJSON(),
      ...Object.fromEntries(
        this.tags.map((tag) => [
          `${METER_V2_WIRELESS_TAGS_PREFIX}${tag.name}`,
          tag.toJSON(this.getServiceSetsForTag(tag.name)),
        ]),
      ),
      ...Object.fromEntries(
        this.vlans.map((vlan) => [`${METER_V1_NETWORK_VLAN_PREFIX}${vlan.name}`, vlan.toJSON()]),
      ),
      ...Object.fromEntries(
        this.accessPoints.map((ap) => [
          `${METER_V2_WIRELESS_ACCESS_POINTS_PREFIX}${ap.name}`,
          ap.toJSON(),
        ]),
      ),
    };
  }
}
