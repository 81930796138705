const size = {
  primary: 24,
  secondary: 16,
  tertiary: 12,
};

export const sizing = {
  primary: size.primary,
  secondary: size.secondary,
  tertiary: size.tertiary,

  /** Framing */
  ends: `${size.secondary}px`,
  endsOnly: `${size.secondary}px 0`,
  sides: `${size.primary}px`,
  sidesOnly: `0 ${size.primary}px`,
  square: `${size.primary}px`,
  squish: `${size.secondary}px ${size.primary}px`,

  /** Content */
  contentEnds: `${size.tertiary}px`,
  contentEndsOnly: `${size.tertiary}px 0`,
  contentSides: `${size.secondary}px`,
  contentSidesOnly: `0 ${size.secondary}px`,
  contentSquare: `${size.secondary}px`,
  contentSquish: `${size.tertiary}px ${size.secondary}px`,
};

export type GutterProp =
  | 'all'
  | 'bottom'
  | 'horizontal'
  | 'left'
  | 'none'
  | 'right'
  | 'top'
  | 'vertical';
