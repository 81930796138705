import { colors, darkThemeSelector, shadows } from '../../stitches.config';

export const selectors = {
  // States
  hover: '&:hover',
  focus: "&:focus-visible, &:has(:focus-visible), &[data-focus-visible='true']",

  // Browsers
  safari: `@supports (font: -apple-system-body) and (-webkit-appearance: none)`,
};

export const transitions = {
  control: 'all 150ms ease-out',
};

export const labelColor = '$$labelColor';
export const iconColor = '$$iconColor';

export const raisedEnabledStyles = {
  [labelColor]: colors.gray600,
  [iconColor]: colors.gray500,
  backgroundColor: colors.gray50,
  boxShadow: shadows.controlRaisedInitialLight,

  [selectors.hover]: {
    [labelColor]: colors.gray800,
    [iconColor]: colors.gray700,
    backgroundColor: colors.white,
    boxShadow: shadows.controlRaisedHoveredLight,
  },

  [selectors.focus]: {
    boxShadow: shadows.controlRaisedFocusedLight,
  },

  [darkThemeSelector]: {
    [labelColor]: colors.gray50,
    [iconColor]: colors.gray100,
    backgroundColor: colors.gray700,
    boxShadow: shadows.controlRaisedInitialDark,

    [selectors.hover]: {
      [labelColor]: colors.white,
      [iconColor]: colors.gray50,
      backgroundColor: colors.gray600,
      boxShadow: shadows.controlRaisedHoveredDark,
    },

    [selectors.focus]: {
      boxShadow: shadows.controlRaisedFocusedDark,
    },
  },
};

export const raisedDisabledStyles = {
  [labelColor]: colors.gray400,
  [iconColor]: colors.gray300,
  boxShadow: shadows.controlRaisedDisabledLight,

  [darkThemeSelector]: {
    [labelColor]: colors.gray200,
    [iconColor]: colors.gray300,
    boxShadow: shadows.controlRaisedDisabledDark,
  },
};

export const raisedEnabledSelectedStyles = {
  [labelColor]: colors.brand50,
  [iconColor]: colors.brand100,
  backgroundColor: colors.brand600,
  boxShadow: shadows.controlRaisedInitialLight,

  [darkThemeSelector]: {
    backgroundColor: colors.brand600,
    boxShadow: shadows.controlRaisedInitialDark,
  },

  [selectors.hover]: {
    [labelColor]: colors.white,
    [iconColor]: colors.brand50,
    backgroundColor: colors.brand700,
    boxShadow: shadows.controlRaisedHoveredLight,

    [darkThemeSelector]: {
      [selectors.hover]: {
        backgroundColor: colors.brand500,
        boxShadow: shadows.controlRaisedHoveredDark,
      },
    },
  },

  [selectors.focus]: {
    backgroundColor: colors.brand700,
    boxShadow: shadows.controlRaisedFocusedLight,

    [darkThemeSelector]: {
      [selectors.focus]: {
        backgroundColor: colors.brand500,
        boxShadow: shadows.controlRaisedFocusedDark,
      },
    },
  },
};

export const raisedDisabledSelectedStyles = {
  [labelColor]: colors.brand200,
  [iconColor]: colors.brand300,
  backgroundColor: colors.brand600,
  boxShadow: shadows.controlRaisedDisabledLight,

  [darkThemeSelector]: {
    [labelColor]: colors.brand300,
    [iconColor]: colors.brand400,
    boxShadow: shadows.controlRaisedDisabledDark,
  },
};
