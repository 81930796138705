import type { ComponentProps } from 'react';
import React from 'react';

import type { PolymorphicRef } from '../../utilities/types/polymorphicAsProp';
import type { ShellContentProps, ShellHeaderProps, ShellProps } from '../Shell/Shell';
import { styled } from '../../stitches.config';
import { LayoutPane } from '../Layout/Layout';
import { Shell, ShellContent, ShellFooter, ShellHeader } from '../Shell/Shell';

const PaneHeaderContainer = styled(ShellHeader);

export type PaneHeaderProps = ShellHeaderProps;

export function PaneHeader({ ...remaining }: PaneHeaderProps) {
  return <PaneHeaderContainer {...remaining} />;
}

export type PaneContentProps = ShellContentProps;

const PaneContentContainer = styled(ShellContent);

export const PaneContent = React.forwardRef(
  <Tag extends React.ElementType>(
    { background, children, gutter = 'none', ...remaining }: PaneContentProps,
    ref: PolymorphicRef<Tag>,
  ) => (
    <PaneContentContainer gutter={gutter} background={background} {...remaining} ref={ref}>
      {children}
    </PaneContentContainer>
  ),
);

const PaneFooterContainer = styled(ShellFooter);

export type PaneFooterProps = {
  /**
   * Provide primary actions for your drawer.
   */
  actions?: React.ReactNode;
  /**
   * Fallback to `children` if `actions` or `secondaryActions` are not provided.
   */
  children?: React.ReactNode;
  /**
   * Provide secondary actions, positioned at the start of the footer.
   */
  secondaryActions?: React.ReactNode;
};

export function PaneFooter({ actions, children, secondaryActions, ...remaining }: PaneFooterProps) {
  return <PaneFooterContainer start={secondaryActions} end={actions || children} {...remaining} />;
}

export const PaneContainer = styled(Shell, {
  width: '100%',

  variants: {
    variant: {
      adjusted: {
        margin: '0 auto',

        '@mobile': {
          height: '100%',
        },

        '@notMobile': {
          minWidth: '$480',
          maxWidth: '760px',
          padding: '$32 $32 0',
        },
      },
      full: {},
    },
  },
});

export type PaneProps = ShellProps & ComponentProps<typeof PaneContainer>;

export function Pane({ children, variant, ...remaining }: PaneProps) {
  return (
    <LayoutPane>
      <PaneContainer variant={variant} {...remaining}>
        {children}
      </PaneContainer>
    </LayoutPane>
  );
}
