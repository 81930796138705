import React, { useId } from 'react';

import type { IconName } from '../../assets/Icon/Icon';
import type {
  PolymorphicComponentProps,
  PolymorphicRef,
} from '../../utilities/types/polymorphicAsProp';
import { Icon } from '../../assets/Icon/Icon';
import { selectors, transitions } from '../../controls/shared/styles';
import { colors, darkThemeSelector, fontWeights, shadows, styled } from '../../stitches.config';
import { Small } from '../../text/Small';
import { Text } from '../../text/Text';
import { isDefined } from '../../utilities/isDefined';

const TabContainer = styled('button', Text, {
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: '$$focusRing',
  fontWeight: fontWeights.bold,
  whiteSpace: 'nowrap',
  transition: transitions.control,

  [selectors.focus]: {
    outline: 'none',
    $$focusRing: shadows.focusRingLight,

    [darkThemeSelector]: {
      $$focusRing: shadows.focusRingDark,
    },
  },

  variants: {
    hasAnnotation: {
      true: {
        '@notDesktop': {
          paddingRight: '$6',
        },

        '@desktop': {
          paddingRight: '$4',
        },
      },
    },
    internal: {
      true: {},
      false: {},
    },
    isSelected: {
      true: {
        '&::after': {
          content: '',
          position: 'absolute',
          left: 0,
          right: 0,
          display: 'block',
          backgroundColor: '$$indicatorColor',
          borderRadiusTop: '99em',
        },

        color: colors.headingBrandLight,
        $$annotationBackground: colors.tokenBgBrandLight,
        $$annotationColor: colors.headingBrandLight,
        $$iconColor: colors.bodyBrandLight,
        $$indicatorColor: colors.iconBrandLight,

        [darkThemeSelector]: {
          color: colors.headingBrandDark,
          $$annotationBackground: colors.tokenBgBrandDark,
          $$annotationColor: colors.headingBrandDark,
          $$iconColor: colors.bodyBrandDark,
          $$indicatorColor: colors.iconBrandDark,
        },
      },
      false: {
        color: colors.headingNeutralLight,
        $$annotationBackground: colors.bgNeutralLight,
        $$annotationColor: colors.bodyNeutralLight,
        $$iconColor: colors.bodyNeutralLight,

        [darkThemeSelector]: {
          color: colors.headingNeutralDark,
          $$annotationBackground: colors.bgNeutralDark,
          $$annotationColor: colors.bodyNeutralDark,
          $$iconColor: colors.bodyNeutralDark,
        },

        [selectors.hover]: {
          color: colors.headingNeutralLight,
          $$annotationBackground: colors.tokenBgNeutralLight,
          $$annotationColor: colors.bodyNeutralLight,
          $$iconColor: colors.bodyNeutralLight,

          [darkThemeSelector]: {
            color: colors.headingNeutralDark,
            $$annotationBackground: colors.gray700,
            $$annotationColor: colors.bodyNeutralDark,
            $$iconColor: colors.bodyNeutralDark,
          },
        },
      },
    },
    hasSize: {
      'x-small': {
        '&::after': {
          height: '$2',
        },

        '@notDesktop': {
          gap: '$4',
          padding: '$2 $6',
          borderRadius: '$8',
          fontSize: '$14',
          lineHeight: '$20',

          '&::after': {
            bottom: '-$6',
          },
        },

        '@desktop': {
          gap: '$4',
          padding: '0 $4',
          borderRadius: '$6',
          fontSize: '$12',
          lineHeight: '$16',

          '&::after': {
            bottom: '-$8',
          },
        },
      },
      small: {
        '&::after': {
          height: '$4',
          bottom: '-$6',
        },

        '@notDesktop': {
          gap: '$6',
          padding: '$4 $8',
          borderRadius: '$10',
          fontSize: '$16',
          lineHeight: '$24',

          '&::after': {
            bottom: '-$8',
          },
        },

        '@desktop': {
          gap: '$4',
          padding: '$4 $6',
          borderRadius: '$10',
          fontSize: '$14',
          lineHeight: '$20',

          '&::after': {
            bottom: '-$6',
          },
        },
      },
    },
  },
  compoundVariants: [
    {
      internal: true,
      css: {
        color: colors.internalBodyLight,
        $$annotationBackground: colors.internalBgLight,
        $$annotationColor: colors.internalBodyLight,
        $$iconColor: colors.internalIconLight,
        $$indicatorColor: colors.internalIconLight,

        [selectors.hover]: {
          color: colors.internalHeadingLight,
          $$annotationBackground: colors.internalBgLight,
          $$annotationColor: colors.internalBodyLight,
          $$iconColor: colors.internalBodyLight,
        },

        [darkThemeSelector]: {
          color: colors.internalBodyDark,
          $$annotationBackground: colors.internalBgDark,
          $$annotationColor: colors.internalBodyDark,
          $$iconColor: colors.internalIconDark,
          $$indicatorColor: colors.internalIconDark,

          [selectors.hover]: {
            color: colors.internalHeadingDark,
            $$annotationBackground: colors.internalBgDark,
            $$annotationColor: colors.internalBodyDark,
            $$iconColor: colors.internalBodyDark,
          },
        },
      },
    },
  ],
});

const TabIcon = styled(Icon, {
  color: '$$iconColor',

  variants: {
    hasSize: {
      'x-small': {
        width: '$12',
        height: '$12',
      },

      small: {
        width: '$16',
        height: '$16',
      },
    },
  },
});

const TabAnnotation = styled('div', Small, {
  alignItems: 'center',
  justifyContent: 'center',
  transition: transitions.control,
  display: 'flex',
  background: '$$annotationBackground',
  color: '$$annotationColor',
  fontVariantNumeric: 'tabular-nums',
  fontWeight: fontWeights.bold,

  '@notDesktop': {
    minWidth: '$24',
    height: '$20',
    minHeight: '$20',
    paddingX: '$6',
    borderRadius: '$8',
  },

  '@desktop': {
    minWidth: '$20',
    height: '$16',
    minHeight: '$16',
    paddingX: '$4',
    borderRadius: '$6',
  },

  variants: {
    hasSize: {
      'x-small': {
        '@notDesktop': {
          minWidth: '$20',
          height: '$16',
          minHeight: '$16',
          paddingX: '$4',
          borderRadius: '$6',
        },

        '@desktop': {
          minWidth: '$16',
          height: '$14',
          minHeight: '$14',
          paddingX: '$2',
          borderRadius: '$4',
        },
      },
      small: {
        marginLeft: '$2',

        '@notDesktop': {
          minWidth: '$24',
          height: '$20',
          minHeight: '$20',
          paddingX: '$6',
          borderRadius: '$8',
        },

        '@desktop': {
          minWidth: '$20',
          height: '$16',
          minHeight: '$16',
          paddingX: '$4',
          borderRadius: '$6',
        },
      },
    },
  },
});

type TabPropSize = 'x-small' | 'small';
export interface TabProps {
  /**
   * Additional annotation such a result count within the tab.
   * */
  annotation?: React.ReactNode;
  /**
   * Written label for the tab.
   * */
  children?: React.ReactNode;
  /**
   * Set which icon to display, no value displays no icon.
   */
  icon?: IconName;
  /**
   * Boolean to show internal-only styles.
   */
  internal?: boolean;
  /**
   * Key for the tab.
   */
  key?: string;
  /**
   * Written label for the tab.
   * */
  label?: React.ReactNode;
  /**
   * Handles the onClick event.
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Marks the currently selected tab.
   * */
  selected?: boolean;
  /**
   * Size of the tab.
   */
  size?: TabPropSize;
}

function TabInner<Tag extends React.ElementType>(
  {
    annotation,
    children,
    icon,
    internal,
    label,
    selected = false,
    size = 'small',
    ...remaining
  }: PolymorphicComponentProps<Tag, TabProps>,
  forwardedRef: PolymorphicRef<Tag>,
) {
  return (
    <TabContainer
      type="button"
      role="tab"
      aria-selected={selected ? 'true' : 'false'}
      ref={forwardedRef}
      isSelected={selected}
      hasAnnotation={isDefined(annotation)}
      internal={internal}
      hasSize={size}
      {...remaining}
    >
      {isDefined(icon) && <TabIcon hasSize={size} icon={icon} />}
      {label || children}
      {isDefined(annotation) && <TabAnnotation hasSize={size}>{annotation}</TabAnnotation>}
    </TabContainer>
  );
}

export const Tab = React.forwardRef(TabInner) as <D extends React.ElementType = 'button'>(
  props: PolymorphicComponentProps<D, TabProps> & { ref?: PolymorphicRef<D> },
) => ReturnType<typeof TabInner>;

const TabsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$4',
  alignItems: 'center',
  height: '100%',
});

export type TabsProps = {
  /**
   * Pass `Tab` components into this for automatically laid out tabs.
   * */
  children?: React.ReactNode;
  /**
   * Size of the tabs.
   */
  size?: TabPropSize;
  /**
   * Tabs passed in as an array.
   */
  tabs?: TabProps[];
};

export function Tabs({ children, size, tabs, ...remaining }: TabsProps) {
  const key = useId();
  return (
    <TabsContainer role="tablist" {...remaining}>
      {children}
      {tabs?.map((tab) => (
        <Tab size={size} key={`${key}-tab-${tab.label || tab.children}`} {...tab} />
      ))}
    </TabsContainer>
  );
}
