import type { Address4Range } from '@meterup/common';
import { isDefined, safeParseAddress4 } from '@meterup/common';
import { freeze, immerable } from 'immer';

import type { MeterV1NetworkVLANDHCP } from '../generated/types';
import type { ct } from '../schema/config';
import { MeterV1NetworkVLANStaticClient } from './MeterV1NetworkVLANStaticClient';

export class MeterV1NetworkVLAN {
  [immerable] = true;

  private constructor(
    public readonly name: string,
    public staticClients: MeterV1NetworkVLANStaticClient[],
    public json: ct.MeterV1NetworkVLAN,
  ) {}

  static fromJSON(name: string, json: ct.MeterV1NetworkVLAN): MeterV1NetworkVLAN {
    const staticClients = Object.entries(json.dhcp?.['static-clients'] ?? {}).map(
      ([macAddress, staticClientJSON]) =>
        MeterV1NetworkVLANStaticClient.fromJSON(name, macAddress, staticClientJSON),
    );

    return freeze(
      new MeterV1NetworkVLAN(
        name,

        staticClients,
        json,
      ),
      true,
    );
  }

  get vlanId(): number {
    return this.json['vlan-id'] ?? 0;
  }

  get gatewayAddress(): string {
    return this.json.dhcp?.gateway ?? '';
  }

  get usableRange(): Address4Range | null {
    const { subnet } = this;
    if (isDefined(subnet)) {
      return {
        start: subnet.startAddressExclusive(),
        end: subnet.endAddressExclusive(),
      };
    }

    return null;
  }

  get dhcpRange(): Address4Range | null {
    if (this.json.dhcp?.['ip-range']) {
      const parsedPair = this.json.dhcp?.['ip-range']
        ?.split('-')
        .map(safeParseAddress4)
        .filter(isDefined);

      if (parsedPair.length === 2) {
        return {
          start: parsedPair[0],
          end: parsedPair[1],
        };
      }

      return null;
    }
    return null;
  }

  get untagged(): boolean {
    return this.json.untagged ?? false;
  }

  get subnet() {
    if (this.json['ip-address']) {
      return safeParseAddress4(this.json['ip-address']);
    }

    return null;
  }

  get subnetString() {
    return this.json['ip-address'] ? this.json['ip-address'] : null;
  }

  getStaticClientByMacAddress(macAddress: string): MeterV1NetworkVLANStaticClient | null {
    return (
      this.staticClients.find((staticClient) => staticClient.macAddress === macAddress) ?? null
    );
  }

  toJSON(): ct.MeterV1NetworkVLAN {
    let staticClients: MeterV1NetworkVLANDHCP['static-clients'] | undefined;
    // Only serialize static clients if any are present or the original JSON had an empty object
    if (this.staticClients.length > 0) {
      staticClients = Object.fromEntries(
        this.staticClients.map((staticClient) => [staticClient.macAddress, staticClient.toJSON()]),
      );
    } else if (
      this.json.dhcp?.['static-clients'] &&
      Object.keys(this.json.dhcp['static-clients']).length === 0
    ) {
      staticClients = {};
    }

    return {
      ...this.json,
      dhcp: {
        ...this.json.dhcp,
        'static-clients': staticClients,
      },
    };
  }
}
