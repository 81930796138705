/* eslint-disable react/prop-types, react/no-unused-prop-types */
import type { AriaTextFieldProps } from '@react-types/textfield';
import type { InputHTMLAttributes } from 'react';
import React, { useCallback, useMemo, useRef } from 'react';
import { useTextField } from 'react-aria';

import type { ControlSize } from '../../controls/shared/types';
import type { RenameKeys } from '../../types/rename_keys';
import type { InputSharedProps } from '../BaseInput/BaseInput';
import { BaseInput, ClearInputButton } from '../BaseInput/BaseInput';

type RemappedAriaTextFieldProps = RenameKeys<AriaTextFieldProps, { isDisabled: 'disabled' }>;

export interface TextInputProps extends InputSharedProps, RemappedAriaTextFieldProps {
  /**
   * Whether to show a "clear" button to delete the input content. Only available for controlled
   * inputs (those with `value` and `onChange` props).
   */
  hasClear?: boolean;
  invalid?: boolean;
  controlSize?: ControlSize;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
}

export const TextInput = React.forwardRef<HTMLLabelElement, TextInputProps>((props, ref) => {
  const {
    hasClear = false,
    invalid,
    icon,
    prefix,
    suffix,
    width,
    maxWidth,
    minWidth,
    controlSize,
    disabled = false,
    inputProps,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const { inputProps: ariaInputProps } = useTextField({ ...props, isDisabled: disabled }, inputRef);

  const finalInputProps: InputHTMLAttributes<HTMLInputElement> = {
    ...ariaInputProps,
    ...inputProps,
  };

  const { onChange } = props;

  const value = props?.value;
  const hasValue = useMemo(() => !!value, [value]);
  const handleClear = useCallback(() => {
    onChange?.('');
    inputRef?.current?.focus();
  }, [onChange]);

  return (
    <BaseInput
      ref={ref}
      inputRef={inputRef}
      inputProps={finalInputProps}
      invalid={invalid}
      disabled={disabled}
      icon={icon}
      prefix={prefix}
      suffix={
        (hasClear || suffix) && (
          <>
            {hasClear && !!onChange && hasValue && (
              <ClearInputButton
                onClick={handleClear}
                arrangement="hidden-label"
                icon="backspace"
                size="small"
                variant="secondary"
              >
                Clear
              </ClearInputButton>
            )}
            {suffix}
          </>
        )
      }
      controlSize={controlSize}
      width={width}
      maxWidth={maxWidth}
      minWidth={minWidth}
    />
  );
});
