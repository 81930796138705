import { colors, darkThemeSelector, shadows, styled } from '@meterup/atto/src/stitches.config';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { VisualState } from '../core';
import { useCommand } from './Root';

interface Props {
  children: React.ReactNode;
}

const ContentWrapper = styled('div', {
  position: 'fixed',
  zIndex: 3,
  inset: 0,
  pointerEvents: 'none',
});

const ContentContainer = styled('div', {
  position: 'absolute',

  '@notDesktop': {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },

  '@desktop': {
    top: '15%',
    left: '50%',
    width: '100%',
    maxWidth: '680px',
    transform: 'translateX(-50%)',
    padding: '$20',
  },
});

const ContentDialog = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  boxSizing: 'border-box',
  background: colors.bgApplicationLight,
  boxShadow: shadows.modalLight,
  pointerEvents: 'initial',

  [darkThemeSelector]: {
    boxShadow: shadows.modalDark,
    background: colors.bgApplicationDark,
  },

  '@desktop': {
    borderRadius: '12px',
  },
});

export const Content = observer((props: Props) => {
  const { state } = useCommand();

  if (state.ui.visualState === VisualState.Hidden) {
    return null;
  }

  return (
    <ContentWrapper>
      <ContentContainer>
        <ContentDialog>{props.children}</ContentDialog>
      </ContentContainer>
    </ContentWrapper>
  );
});
