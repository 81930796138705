import { freeze, immerable } from 'immer';

import type { ct } from '../schema/config';
import { MeterV1NetworkNATPortForward } from './MeterV1NetworkNATPortForward';

export class MeterV1NetworkNAT {
  [immerable] = true;

  private constructor(
    public portForwards: MeterV1NetworkNATPortForward[],
    public json: ct.MeterV1NetworkNAT,
  ) {}

  static fromJSON(json: ct.MeterV1NetworkNAT): MeterV1NetworkNAT {
    const portForwards = json['port-forwards']?.map(MeterV1NetworkNATPortForward.fromJSON) ?? [];

    return freeze(new MeterV1NetworkNAT(portForwards, json), true);
  }

  toJSON(): ct.MeterV1NetworkNAT {
    const shouldSerializePortForwards =
      this.portForwards.length > 0 || 'port-forwards' in this.json;

    return {
      ...this.json,
      'port-forwards': shouldSerializePortForwards
        ? this.portForwards.map((pf) => pf.toJSON())
        : undefined,
    };
  }
}
